import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getCameras, removeCamera } from "../../actions";
import "./Cameras.scss";
import "typeface-roboto";
import {
  Breadcrumb,
  Button,
  ControlBar,
  InfoBox,
  Input,
  SetupBox,
} from "@kerberos-io/ui";
import ListCameras from "../../components/Cameras/ListCameras";
import CRUDCameras from "../../components/Cameras/CRUDCameras";

class Cameras extends React.Component {
  constructor() {
    super();
    this.state = {
      search: "",
    };
    this.addCamera = this.addCamera.bind(this);
    this.getCameras = this.getCameras.bind(this);
    this.removeCamera = this.removeCamera.bind(this);
    this.filterCameras = this.filterCameras.bind(this);
  }

  componentDidMount() {
    this.getCameras();
  }

  getCameras() {
    this.props.dispatchGetCameras();
  }

  removeCamera(camera) {
    this.props.dispatchRemoveCamera(camera.key, this.getCameras);
  }

  filterCameras(e) {
    const { value } = e.target;
    this.setState({
      search: value,
    });
  }

  addCamera(){
    // do nothing
  }

  render() {
    const { search } = this.state;
    const cameras = this.props.cameras
      ? this.props.cameras.filter(
          (a) => a.analytics[0].cameraname.indexOf(search) >= 0
        )
      : [];
    return (
      <>
        <Breadcrumb
          title={"Cameras"}
          level1={"A list of all your connected cameras."}
          level1Link={""}
        >
          <CRUDCameras mode="Add" onSubmit={this.addCamera}>
            <Button
              label={"Add camera"}
              type={"default"}
              icon={"plus-circle"}
            />
          </CRUDCameras>
        </Breadcrumb>
        <ControlBar>
          <Input
            iconleft="search"
            onChange={this.filterCameras}
            placeholder="Search cameras..."
            layout={"controlbar"}
            type="text"
          />
        </ControlBar>
        <div className={"info"}>
          <InfoBox
            image={"info-surveillance"}
            title={"Connect cameras"}
            description={
              "Connect your Kerberos agents to Kerberos Vault by providing secure credentials. Once your cameras are properly connected to Kerberos Vault they will be listed below."
            }
          ></InfoBox>
        </div>
        {cameras.length > 0 && (
          <ListCameras
            getCameras={this.getCameras}
            removeCamera={this.removeCamera}
            cameras={cameras}
          />
        )}

        {cameras.length === 0 && (
          <SetupBox
            dashed={true}
            header={"Add your camera"}
            text={
              "It looks like you didn't assigned a camera so far, or the camera you are looking for doesn't exists."
            }
            btnicon={"counting"}
            button={
              <CRUDCameras mode="Add" onSubmit={this.addCamera}>
                <Button
                  label={"Add Camera"}
                  icon={"plus-circle"}
                  type={"default"}
                />
              </CRUDCameras>
            }
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  cameras: state.storage.cameras,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatchGetCameras: () => dispatch(getCameras()),
  dispatchRemoveCamera: (id, success) => dispatch(removeCamera(id, success)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Cameras)
);
