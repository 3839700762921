import API from "./api";
import config from "../config";

function redir(url) {
  url = encodeURI(url);
  url = url.replace(/#/g, "%23");
  return url;
}

export function doGetMedia(pagination, onSuccess, onError) {
  let endpoint = API.get(`storage/media/${pagination.rows}/${pagination.page}`);
  endpoint
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(res.data);
      }
      return res.data;
    })
    .then(function (data) {
      onSuccess(data);
    })
    .catch(function (error) {
      onError(error);
    });
}

export function doGetStorageProvider(onSuccess, onError) {
  let endpoint = API.get(`storage/providers`);
  endpoint
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(res.data);
      }
      return res.data;
    })
    .then(function (data) {
      onSuccess(data);
    })
    .catch(function (error) {
      onError(error);
    });
}

export function doAddStorageProvider(data, onSuccess, onError) {
  let endpoint = API.post(`storage/providers`, {
    ...data,
  });
  endpoint
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(res.data);
      }
      return res.data;
    })
    .then(function (data) {
      onSuccess(data);
    })
    .catch(function (error) {
      onError(error);
    });
}

export function doTestStorageProvider(data, onSuccess, onError) {
  let endpoint = API.post(`storage/test-provider`, {
    ...data,
  });
  endpoint
    .then((res) => {
      return res.data;
    })
    .then(function (data) {
      onSuccess(data);
    })
    .catch(function (error) {
      const { data } = error.response;
      onError(data.data);
    });
}

export function doEditStorageProvider(data, onSuccess, onError) {
  let endpoint = API.put(`storage/providers/${data.id}`, {
    ...data,
  });
  endpoint
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(res.data);
      }
      return res.data;
    })
    .then(function (data) {
      onSuccess(data);
    })
    .catch(function (error) {
      onError(error);
    });
}

export function doRemoveStorageProvider(id, onSuccess, onError) {
  let endpoint = API.delete(`storage/providers/${id}`);
  endpoint
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(res.data);
      }
      return res.data;
    })
    .then(function (data) {
      onSuccess(data);
    })
    .catch(function (error) {
      onError(error);
    });
}

export function doGetQueue(onSuccess, onError) {
  let endpoint = API.get(`storage/queues`);
  endpoint
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(res.data);
      }
      return res.data;
    })
    .then(function (data) {
      onSuccess(data);
    })
    .catch(function (error) {
      onError(error);
    });
}

export function doTestQueue(data, onSuccess, onError) {
  let endpoint = API.post(`storage/test-queue`, {
    ...data,
  });
  endpoint
    .then((res) => {
      return res.data;
    })
    .then(function (data) {
      onSuccess(data);
    })
    .catch(function (error) {
      const { data } = error.response;
      onError(data.data);
    });
}

export function doAddQueue(data, onSuccess, onError) {
  let endpoint = API.post(`storage/queues`, {
    ...data,
  });
  endpoint
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(res.data);
      }
      return res.data;
    })
    .then(function (data) {
      onSuccess(data);
    })
    .catch(function (error) {
      onError(error);
    });
}

export function doEditQueue(data, onSuccess, onError) {
  let endpoint = API.put(`storage/queues/${data.id}`, {
    ...data,
  });
  endpoint
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(res.data);
      }
      return res.data;
    })
    .then(function (data) {
      onSuccess(data);
    })
    .catch(function (error) {
      onError(error);
    });
}

export function doRemoveQueue(id, onSuccess, onError) {
  let endpoint = API.delete(`storage/queues/${id}`);
  endpoint
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(res.data);
      }
      return res.data;
    })
    .then(function (data) {
      onSuccess(data);
    })
    .catch(function (error) {
      onError(error);
    });
}

export function doGetForwards(onSuccess, onError) {}

export function doAddForwards(data, onSuccess, onError) {
  let endpoint = API.post(`storage/forwards`, {
    ...data,
  });
  endpoint
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(res.data);
      }
      return res.data;
    })
    .then(function (data) {
      onSuccess(data);
    })
    .catch(function (error) {
      onError(error);
    });
}

export function doEditForwards(data, onSuccess, onError) {
  let endpoint = API.put(`storage/forwards/${data.id}`, {
    ...data,
  });
  endpoint
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(res.data);
      }
      return res.data;
    })
    .then(function (data) {
      onSuccess(data);
    })
    .catch(function (error) {
      onError(error);
    });
}

export function doRemoveForwards(id, onSuccess, onError) {
  let endpoint = API.delete(`storage/forwards/${id}`);
  endpoint
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(res.data);
      }
      return res.data;
    })
    .then(function (data) {
      onSuccess(data);
    })
    .catch(function (error) {
      onError(error);
    });
}

export function doGetAccount(onSuccess, onError) {
  let endpoint = API.get(`storage/accounts`);
  endpoint
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(res.data);
      }
      return res.data;
    })
    .then(function (data) {
      onSuccess(data);
    })
    .catch(function (error) {
      onError(error);
    });
}

export function doAddAccount(data, onSuccess, onError) {
  let endpoint = API.post(`storage/accounts`, {
    ...data,
  });
  endpoint
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(res.data);
      }
      return res.data;
    })
    .then(function (data) {
      onSuccess(data);
    })
    .catch(function (error) {
      onError(error);
    });
}

export function doEditAccount(data, onSuccess, onError) {
  let endpoint = API.put(`storage/accounts/${data.id}`, {
    ...data,
  });
  endpoint
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(res.data);
      }
      return res.data;
    })
    .then(function (data) {
      onSuccess(data);
    })
    .catch(function (error) {
      onError(error);
    });
}

export function doRemoveAccount(id, onSuccess, onError) {
  let endpoint = API.delete(`storage/accounts/${id}`);
  endpoint
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(res.data);
      }
      return res.data;
    })
    .then(function (data) {
      onSuccess(data);
    })
    .catch(function (error) {
      onError(error);
    });
}

export function doGetMediaContent(fileName, providerName, account) {
  let baseUrl = config.API_URL + "storage/content/media";
  if (fileName !== "") {
    baseUrl += "?file=" + fileName;
  }
  if (providerName !== "") {
    baseUrl += "&provider=" + providerName;
  }
  if (account.accesskey !== "") {
    baseUrl += "&accesskey=" + redir(account.access_key);
  }
  if (account.secretaccesskey !== "") {
    baseUrl += "&secretaccesskey=" + redir(account.secret_access_key);
  }
  return baseUrl;
}

export function doGetCameras(onSuccess, onError) {
  let endpoint = API.get(`devices`);
  endpoint
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(res.data);
      }
      return res.data;
    })
    .then(function (data) {
      onSuccess(data);
    })
    .catch(function (error) {
      onError(error);
    });
}

export function doRemoveCamera(id, onSuccess, onError) {
  let endpoint = API.delete(`devices/${id}`);
  endpoint
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(res.data);
      }
      return res.data;
    })
    .then(function (data) {
      onSuccess(data);
    })
    .catch(function (error) {
      onError(error);
    });
}
