import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ListUsers from '../../components/ListUsers';
import { getUsers } from '../../actions';
import TablePagination from '@material-ui/core/TablePagination';
import './Users.css';
import 'typeface-roboto';

class Users extends React.Component {

  constructor() {
    super();
    this.state = {
      page: 0,
      rows: 50,
    }
    this.updateData = this.updateData.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
  }

  componentDidMount() {
    const { rows, page } = this.state;
    this.updateData(rows, page);
  }

  handleChangePage(event, page){
    this.setState({
      page
    });
    this.updateData(this.state.rows, page);
  }

  handleChangeRowsPerPage(event){
    const rows = parseInt(event.target.value, 10);
    this.setState({
      rows
    });
    this.updateData(rows, this.state.page);
  }

  updateData(rows, page){
    this.props.dispatchGetUsers({
      rows,
      page,
    });
  }

  render() {
    return <div>
          <div className="actions">
            <TablePagination
               component="div"
               count={this.props.usersCount || 0}
               page={this.state.page}
               onChangePage={this.handleChangePage}
               rowsPerPage={this.state.rows}
               onChangeRowsPerPage={this.handleChangeRowsPerPage}
             />
          </div>
          <div className="list">
            <ListUsers
              users={this.props.users} />
          </div>
      </div>
  }
}

const mapStateToProps = (state, ownProps) => ({
  users: state.cloud.users,
  usersCount: state.cloud.usersCount,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatchGetUsers: (pagination) => dispatch(getUsers(pagination)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Users));
