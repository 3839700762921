const storage = (
  state = {
    providers: [],
    queues: [],
    accounts: [],
    forwards: [],
    media: [],
    cameras: [],
    mediaCount: 0,
    queueStatus: "",
    queueError: "",
    providerStatus: "",
    providerError: "",
  },
  action
) => {
  switch (action.type) {
    case "GET_STORAGE_PROVIDER":
      return {
        ...state,
        providers: action.providers,
      };
    case "GET_QUEUE":
      return {
        ...state,
        queues: action.queues,
      };
    case "GET_ACCOUNT":
      return {
        ...state,
        accounts: action.accounts,
      };
    case "GET_CAMERAS":
      return {
        ...state,
        cameras: action.cameras,
      };
    case "GET_FORWARDS":
      return {
        ...state,
        forwards: action.forwards,
      };
    case "GET_MEDIA":
      return {
        ...state,
        media: [...action.media],
        mediaCount: action.mediaCount,
      };
    case "TEST_STORAGE_PROVIDER":
      return {
        ...state,
        providerStatus: "pending",
        providerError: "",
      };
    case "TEST_STORAGE_PROVIDER_SUCCESS":
      return {
        ...state,
        providerStatus: "success",
        providerError: "",
      };
    case "TEST_STORAGE_PROVIDER_ERROR":
      return {
        ...state,
        providerStatus: "error",
        providerError: action.error,
      };
    case "TEST_QUEUE":
      return {
        ...state,
        queueStatus: "pending",
        queueError: "",
      };
    case "TEST_QUEUE_SUCCESS":
      return {
        ...state,
        queueStatus: "success",
        queueError: "",
      };
    case "TEST_QUEUE_ERROR":
      return {
        ...state,
        queueStatus: "error",
        queueError: action.error,
      };
    default:
      return state;
  }
};

export default storage;
