import React from "react";
import { connect } from "react-redux";
import "./CRUDQueue.scss";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Icon,
  Dropdown,
  Button,
  Toggle,
  InfoBar,
} from "@kerberos-io/ui";

class CRUDQueue extends React.Component {
  constructor() {
    super();
    this.state = {
      open: false,
      id: "",
      name: "",
      queue: "sqs",
      forward_type: "continuous",
      enabled: "false",
      preview_enabled: "false",
      region: "",
      exchange: "",
      topic: "",
      broker: "",
      group: "",
      username: "",
      password: "",
      mechanism: "",
      security: "",
      url: "",
      vault_url: "",
      provider: "",
      cloudkey: "",
      access_key: "",
      secret: "",
      errors: {},
      error: false,
      validated: false,
    };

    this.handleClose = this.handleClose.bind(this);
    this.submitIntegration = this.submitIntegration.bind(this);
    this.verifyIntegration = this.verifyIntegration.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.changeIntegration = this.changeIntegration.bind(this);
    this.changeForwarding = this.changeForwarding.bind(this);
    this.escFunction = this.escFunction.bind(this);
  }

  componentDidMount() {
    const q = this.props.queue;
    if (q) {
      this.setState({ ...q });
    }
    document.addEventListener("keydown", this.escFunction, false);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  handleChange(event) {
    this.setState({
      queue: event.target.value,
    });
  }
  œ;
  validateIntegration() {
    if (this.state && this.state.queue) {
      const verification = this.verifyIntegration();

      if (!verification.error) {
        this.props.onValidate(verification.payload);
      } else {
        // ....
      }

      return { payload: verification.payload, error: verification.error };
    }

    return { payload: {}, error: true };
  }

  verifyIntegration() {
    let error = false;

    const payload = {
      id: this.state.id,
      queue: this.state.queue,
      enabled: this.state.enabled,
    };

    const { name } = this.state;
    const errors = {};
    payload.name = name;
    if (name === "") {
      errors["name"] = "Please specify a name for the integration";
      error = true;
    }

    if (payload.queue === "kafka") {
      const { broker, group, topic, username, password, mechanism, security } =
        this.state;

      payload.broker = broker;
      if (broker === "") {
        errors["broker"] = "The Kafka broker's url";
        error = true;
      }

      payload.group = group;
      if (group === "") {
        errors["group"] = "The Kafka group messages will be send to";
        error = true;
      }

      payload.topic = topic;
      if (topic === "") {
        errors["topic"] = "The topic that will be receiving messages";
        error = true;
      }

      payload.username = username;
      if (username === "") {
        errors["username"] = "The username specified in the ACL records";
        error = true;
      }

      payload.password = password;
      if (password === "") {
        errors["password"] = "The password specified in the ACL records";
        error = true;
      }

      payload.mechanism = mechanism;
      if (mechanism === "") {
        errors["mechanism"] = "The mechanism used to configure Kafka (PLAIN)";
        error = true;
      }

      payload.security = security;
      if (security === "") {
        errors["security"] = "Provide the security settings (SASL_PLAINTEXT)";
        error = true;
      }
    } else if (payload.queue === "rabbitmq") {
      const { broker, exchange, topic, username, password } =
        this.state;

      payload.broker = broker;
      if (broker === "") {
        errors["broker"] = "The RabbitMQ broker's url";
        error = true;
      }

      payload.exchange = exchange;
      // Exchange is optional

      payload.topic = topic;
      if (topic === "") {
        errors["topic"] = "The queue that will be receiving messages";
        error = true;
      }

      payload.username = username;
      if (username === "") {
        errors["username"] = "The username specified.";
        error = true;
      }

      payload.password = password;
      if (password === "") {
        errors["password"] = "The password specified.";
        error = true;
      }
    } else if (payload.queue === "sqs") {
      const { region, topic, access_key, secret } = this.state;

      payload.region = region;
      if (region === "") {
        errors["region"] = "Provide the region where SQS is running";
        error = true;
      }

      payload.topic = topic;
      if (topic === "") {
        errors["topic"] =
          "Provide the topic where the messages will be send to";
        error = true;
      }

      payload.access_key = access_key;
      if (access_key === "") {
        errors["access_key"] = "The access key of your IAM user";
        error = true;
      }

      payload.secret = secret;
      if (secret === "") {
        errors["secret"] = "The secret access key of your IAM user";
        error = true;
      }
    } else if (payload.queue === "kerberosvault") {
      const {
        vault_url,
        provider,
        forward_type,
        access_key,
        secret,
        url,
        cloudkey,
        username,
        preview_enabled,
      } = this.state;

      payload.vault_url = vault_url;
      if (vault_url === "") {
        errors["vault_url"] = "Specify a valid Kerberos Vault API";
        error = true;
      }

      payload.provider = provider;
      if (provider === "") {
        errors["provider"] =
          "Specify a valid provider of your remote Kerberos Vault";
        error = true;
      }

      // Could be empty when switching from another integration
      payload.forward_type = forward_type === "" ? "continuous" : forward_type;
      if (payload.forward_type === "") {
        errors["forwarding"] = "Specify a valid forwarding type";
        error = true;
      }

      payload.access_key = access_key;
      if (access_key === "") {
        errors["access_key"] = "The access key of your Kerberos Vault account";
        error = true;
      }

      payload.secret = secret;
      if (secret === "") {
        errors["secret"] =
          "The secret access key of your Kerberos Vault account";
        error = true;
      }

      payload.preview_enabled = preview_enabled;
      if (preview_enabled === "") {
        errors["secret"] = "The value for preview enabled is missing";
        error = true;
      }

      if (forward_type && forward_type === "ondemand") {
        payload.url = url;
        if (url === "") {
          errors["url"] = "Specify a valid Kerberos Hub API";
          error = true;
        }

        payload.cloudkey = cloudkey;
        if (cloudkey === "") {
          errors["cloudkey"] =
            "The public key assigned to your Kerberos Hub username";
          error = true;
        }

        payload.username = username;
        if (username === "") {
          errors["username"] = "Your Kerberos Hub username";
          error = true;
        }
      }
    } else if (payload.queue === "kerberoscloud") {
      const { cloudkey, url } = this.state;

      payload.cloudkey = cloudkey;
      if (cloudkey === "") {
        errors["cloudkey"] =
          "Cloud key is required to link your vault recordings";
        error = true;
      }

      payload.url = url;
      if (url === "") {
        errors["url"] = "Specify a valid Kerberos Hub url";
        error = true;
      }
    }

    this.setState({
      error: error,
      errors: errors,
      validated: true,
    });

    return { payload, error, errors };
  }

  submitIntegration(event) {
    const valid = this.verifyIntegration();
    if (!valid.error && this.props.onSubmit) {
      this.props.onSubmit(valid.payload);
      this.handleClose();
    }
    event.preventDefault();
    return false;
  }

  changeHandler(event, field) {
    this.setState({
      [field]: event.target.value,
    });
  }

  handleClickOpen() {
    this.setState({
      open: true,
    });
  }

  handleClose() {
    this.setState({
      open: false,
    });
  }

  changeIntegration(value) {
    this.setState({
      queue: value[0],
    });
  }

  changeForwarding(value) {
    this.setState({
      forward_type: value[0],
    });
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      //Do whatever when esc is pressed
      this.handleClose();
    }
  }

  render() {
    const integrations = [
      { label: "SQS", value: "sqs" },
      { label: "Kafka", value: "kafka" },
      { label: "RabbitMQ", value: "rabbitmq"},
      { label: "Kerberos Vault", value: "kerberosvault" },
      { label: "Kerberos Hub", value: "kerberoscloud" },
    ];

    const forwardings = [
      { label: "Continuous", value: "continuous" },
      { label: "On demand", value: "ondemand" },
    ];

    const { open, queue } = this.state;

    let integrationName = "";
    if (queue) {
      const findIntegration = integrations.find((i) => i.value === queue);
      if (findIntegration) {
        integrationName = findIntegration.label;
      }
    }

    return (
      <div className={"create-queue"}>
        {open && (
          <Modal>
            <form onSubmit={(e) => this.submitIntegration(e)} noValidate>
              <ModalHeader
                title={
                  integrationName !== ""
                    ? `${this.props.mode} a ${integrationName} integration`
                    : `${this.props.mode} an integration`
                }
                onClose={() => this.handleClose()}
              />
              <ModalBody>
                {/* Once an integration is selected, provide the connection details */}
                {this.state.validated && !this.state.error && (
                  <div>
                    {this.props.queueStatus === "pending" && (
                      <InfoBar
                        message={"Verifying your configuration"}
                        type={"loading"}
                      />
                    )}
                    {this.props.queueStatus === "error" && (
                      <InfoBar
                        message={
                          "Configuration is not correct and not working: " +
                          this.props.queueError
                        }
                        type={"alert"}
                      />
                    )}
                    {this.props.queueStatus === "success" && (
                      <InfoBar
                        message={"Configuration is valid and working"}
                        type={"success"}
                      />
                    )}{" "}
                  </div>
                )}

                <div className={"grid-container --two-columns"}>
                  <div className={"block"}>
                    <div className={"title"}>
                      <Icon label={"counting"} />
                      <h1>Select Integration</h1>
                    </div>
                    <Dropdown
                      icon={"counting"}
                      placeholder={"Select your integration"}
                      onChange={this.changeIntegration}
                      isRadio={true}
                      selected={[this.state.queue]}
                      items={integrations}
                    ></Dropdown>

                    <div className={"title"}>
                      <Icon label={"info"} />
                      <h1>Connection</h1>
                      <div
                        className={"toggle-integration"}
                        onClick={() =>
                          this.setState({
                            enabled:
                              this.state.enabled === "true" ? "false" : "true",
                          })
                        }
                      >
                        <Toggle on={this.state.enabled === "true"} />
                        <span>
                          {this.state.enabled === "true"
                            ? "Enabled"
                            : "Disabled"}
                        </span>
                      </div>
                    </div>

                    <Input
                      label={"Integration name"}
                      placeholder={"Provide a good name"}
                      type={"text"}
                      value={this.state.name}
                      error={this.state.errors["name"]}
                      onChange={(event) => this.changeHandler(event, "name")}
                    />

                    {/* AWS SQS credentials */}
                    {queue === "sqs" && (
                      <>
                        <Input
                          label={"Region"}
                          placeholder={"The region where you consume SQS"}
                          type={"text"}
                          value={this.state.region}
                          error={this.state.errors["region"]}
                          onChange={(event) =>
                            this.changeHandler(event, "region")
                          }
                        />
                        <Input
                          label={"Topic"}
                          placeholder={
                            "The topic name to which messages will be send"
                          }
                          type={"text"}
                          value={this.state.topic}
                          error={this.state.errors["topic"]}
                          onChange={(event) =>
                            this.changeHandler(event, "topic")
                          }
                        />
                      </>
                    )}

                    {/* Kafka credentials */}
                    {queue === "kafka" && (
                      <>
                        <Input
                          label={"Broker"}
                          placeholder={
                            "The brokers from which we will consume."
                          }
                          type={"text"}
                          value={this.state.broker}
                          error={this.state.errors["broker"]}
                          onChange={(event) =>
                            this.changeHandler(event, "broker")
                          }
                        />
                        <Input
                          label={"Group"}
                          placeholder={"Specify a Kafka group for the consumer"}
                          type={"text"}
                          value={this.state.group}
                          error={this.state.errors["group"]}
                          onChange={(event) =>
                            this.changeHandler(event, "group")
                          }
                        />
                        <Input
                          label={"Topic"}
                          placeholder={
                            "The topic name to which messages will be send"
                          }
                          type={"text"}
                          value={this.state.topic}
                          error={this.state.errors["topic"]}
                          onChange={(event) =>
                            this.changeHandler(event, "topic")
                          }
                        />
                      </>
                    )}

                     {/* Rabbitmq credentials */}
                     {queue === "rabbitmq" && (
                      <>
                        <Input
                          label={"Broker"}
                          placeholder={
                            "The brokers from which we will consume."
                          }
                          type={"text"}
                          value={this.state.broker}
                          error={this.state.errors["broker"]}
                          onChange={(event) =>
                            this.changeHandler(event, "broker")
                          }
                        />
                        <Input
                          label={"Exchange"}
                          placeholder={"Specify the RabbitMQ exchange (or leave empty for default)"}
                          type={"text"}
                          value={this.state.exchange}
                          error={this.state.errors["exchange"]}
                          onChange={(event) =>
                            this.changeHandler(event, "exchange")
                          }
                        />
                        <Input
                          label={"Queue"}
                          placeholder={
                            "The queue to which messages will be send"
                          }
                          type={"text"}
                          value={this.state.topic}
                          error={this.state.errors["topic"]}
                          onChange={(event) =>
                            this.changeHandler(event, "topic")
                          }
                        />
                      </>
                    )}

                    {/* Kerberos Vault credentials */}
                    {queue === "kerberosvault" && (
                      <>
                        <Dropdown
                          icon={"counting"}
                          label={"Forwarding mode"}
                          placeholder={"Select your integration"}
                          onChange={this.changeForwarding}
                          isRadio={true}
                          selected={[
                            this.state.forward_type === ""
                              ? "continuous"
                              : this.state.forward_type,
                          ]}
                          items={forwardings}
                        ></Dropdown>

                        <Input
                          label={"Url"}
                          placeholder={"The Kerberos Vault API url"}
                          type={"text"}
                          value={this.state.vault_url}
                          error={this.state.errors["vault_url"]}
                          onChange={(event) =>
                            this.changeHandler(event, "vault_url")
                          }
                        />
                        <Input
                          label={"Provider"}
                          placeholder={
                            "The provider where the media will be stored"
                          }
                          type={"text"}
                          value={this.state.provider}
                          error={this.state.errors["provider"]}
                          onChange={(event) =>
                            this.changeHandler(event, "provider")
                          }
                        />
                      </>
                    )}

                    {/* Kerberos Hub credentials */}
                    {queue === "kerberoscloud" && (
                      <>
                        <Input
                          label={"Kerberos Hub Url"}
                          placeholder={"The url of Kerberos Hub"}
                          type={"text"}
                          value={this.state.url}
                          error={this.state.errors["url"]}
                          onChange={(event) => this.changeHandler(event, "url")}
                        />
                      </>
                    )}
                  </div>

                  {/* AWS SQS credentials */}
                  {queue === "sqs" && (
                    <div className={"block"}>
                      <div className={"title"}>
                        <Icon label={"lock"} />
                        <h1>AWS SQS credentials</h1>
                      </div>
                      <p>
                        To connect your AWS SQS queue, make sure{" "}
                        <a
                          href={
                            "https://docs.aws.amazon.com/AWSSimpleQueueService/latest/SQSDeveloperGuide/sqs-setting-up.html#sqs-getting-access-key-id-secret-access-key"
                          }
                          target={"_blank"}
                          rel={"noreferrer"}
                        >
                          you have created an IAM user
                        </a>{" "}
                        through the AWS console, and given it the correct
                        permissions.
                      </p>
                      <Input
                        label={"Access key"}
                        placeholder={
                          "Retrieve the access key from your IAM user"
                        }
                        type={"text"}
                        value={this.state.access_key}
                        error={this.state.errors["access_key"]}
                        onChange={(event) =>
                          this.changeHandler(event, "access_key")
                        }
                      />
                      <Input
                        label={"Secret access key"}
                        placeholder={
                          "Retrieve the secret access key from your IAM user"
                        }
                        type={"password"}
                        iconleft=""
                        iconright="activity"
                        seperate
                        value={this.state.secret}
                        error={this.state.errors["secret"]}
                        onChange={(event) =>
                          this.changeHandler(event, "secret")
                        }
                      />
                    </div>
                  )}

                  {/* Kafka credentials */}
                  {queue === "kafka" && (
                    <div className={"block"}>
                      <div className={"title"}>
                        <Icon label={"lock"} />
                        <h1>Kafka credentials</h1>
                      </div>
                      <p>
                        To connect your Kafka broker, make sure{" "}
                        <a
                          href={
                            "https://docs.confluent.io/3.0.0/kafka/sasl.html"
                          }
                          target={"_blank"}
                          rel={"noreferrer"}
                        >
                          you have the relevant information
                        </a>
                        , credentials, SASL settings etc.
                      </p>
                      <Input
                        label={"Username"}
                        placeholder={"The username of your Kafka ACL user"}
                        type={"text"}
                        value={this.state.username}
                        error={this.state.errors["username"]}
                        onChange={(event) =>
                          this.changeHandler(event, "username")
                        }
                      />
                      <Input
                        label={"Password"}
                        placeholder={"The password of your Kafka ACL user"}
                        type={"password"}
                        readonly={false}
                        disabled={false}
                        name="password"
                        iconleft=""
                        iconright="activity"
                        seperate
                        value={this.state.password}
                        error={this.state.errors["password"]}
                        onChange={(event) =>
                          this.changeHandler(event, "password")
                        }
                      />
                      <Input
                        label={"Mechanism"}
                        placeholder={"The mechanism of your Kafka broker"}
                        type={"mechanism"}
                        value={this.state.mechanism}
                        error={this.state.errors["mechanism"]}
                        onChange={(event) =>
                          this.changeHandler(event, "mechanism")
                        }
                      />
                      <Input
                        label={"Security"}
                        placeholder={"The username of your Kafka ACL user"}
                        type={"text"}
                        value={this.state.security}
                        error={this.state.errors["security"]}
                        onChange={(event) =>
                          this.changeHandler(event, "security")
                        }
                      />
                    </div>
                  )}

                  {/* RabbitMQ credentials */}
                  {queue === "rabbitmq" && (
                    <div className={"block"}>
                      <div className={"title"}>
                        <Icon label={"lock"} />
                        <h1>RabbitMQ credentials</h1>
                      </div>

                      <p>
                        Provide the username and password to establish a connection to your RabbitMQ broker.
                      </p>
                      <Input
                        label={"Username"}
                        placeholder={"The username of your RabbitMQ user"}
                        type={"text"}
                        value={this.state.username}
                        error={this.state.errors["username"]}
                        onChange={(event) =>
                          this.changeHandler(event, "username")
                        }
                      />
                      <Input
                        label={"Password"}
                        placeholder={"The password of your RabbitMQ user"}
                        type={"password"}
                        readonly={false}
                        disabled={false}
                        name="password"
                        iconleft=""
                        iconright="activity"
                        seperate
                        value={this.state.password}
                        error={this.state.errors["password"]}
                        onChange={(event) =>
                          this.changeHandler(event, "password")
                        }
                      />
                    </div>
                  )}

                  {/* Kerberos Vault credentials */}
                  {queue === "kerberosvault" && (
                    <div className={"block"}>
                      <div className={"title"}>
                        <Icon label={"lock"} />
                        <h1>Kerberos Vault credentials</h1>
                      </div>
                      <p>
                        To connect your Kerberos Vault to another Kerberos
                        Vault,{" "}
                        <a
                          href={"https://doc.kerberos.io/vault/get-started/"}
                          target={"_blank"}
                          rel={"noreferrer"}
                        >
                          {" "}
                          you will need to create an account
                        </a>{" "}
                        on the remote Kerberos Vault and specify those
                        credentials here.
                      </p>

                      <Input
                        label={"Access key"}
                        placeholder={
                          "Retrieve the access key from your IAM user"
                        }
                        type={"text"}
                        value={this.state.access_key}
                        error={this.state.errors["access_key"]}
                        onChange={(event) =>
                          this.changeHandler(event, "access_key")
                        }
                      />
                      <Input
                        label={"Secret access key"}
                        placeholder={
                          "Retrieve the secret access key from your IAM user"
                        }
                        type={"password"}
                        readonly={false}
                        disabled={false}
                        name="password"
                        iconleft=""
                        iconright="activity"
                        seperate
                        value={this.state.secret}
                        error={this.state.errors["secret"]}
                        onChange={(event) =>
                          this.changeHandler(event, "secret")
                        }
                      />

                      {this.state.forward_type === "ondemand" && (
                        <>
                          <div className={"title"}>
                            <Icon label={"lock"} />
                            <h1>Kerberos Hub credentials</h1>
                          </div>

                          <Input
                            label={"Kerberos Hub Url"}
                            placeholder={"The url of Kerberos Hub"}
                            type={"text"}
                            value={this.state.url}
                            error={this.state.errors["url"]}
                            onChange={(event) =>
                              this.changeHandler(event, "url")
                            }
                          />

                          <Input
                            label={"Hub public key"}
                            placeholder={"Specify your Kerberos Hub public key"}
                            readonly={false}
                            type="password"
                            name="password"
                            iconleft=""
                            iconright="activity"
                            seperate
                            value={this.state.cloudkey}
                            error={this.state.errors["cloudkey"]}
                            onChange={(event) =>
                              this.changeHandler(event, "cloudkey")
                            }
                          />

                          <Input
                            label={"Hub username"}
                            placeholder={"Specify your Kerberos Hub username"}
                            type="text"
                            value={this.state.username}
                            error={this.state.errors["username"]}
                            onChange={(event) =>
                              this.changeHandler(event, "username")
                            }
                          />

                          <div className={"title"}>
                            <Icon label={"media"} />
                            <h1>Kerberos Hub Preview</h1>
                            <div
                              className={"toggle-integration"}
                              onClick={() =>
                                this.setState({
                                  preview_enabled:
                                    this.state.preview_enabled === "true"
                                      ? "false"
                                      : "true",
                                })
                              }
                            >
                              <Toggle
                                on={this.state.preview_enabled === "true"}
                              />
                              <span>
                                {this.state.preview_enabled === "true"
                                  ? "Enabled"
                                  : "Disabled"}
                              </span>
                            </div>
                          </div>
                          <p>
                            If enabled recordings will be made available
                            on-demand. While the recordings remain at the edge,
                            a snapshot will be send to Kerberos Hub.
                          </p>
                        </>
                      )}
                    </div>
                  )}

                  {/* Kerberos Cloud credentials */}
                  {queue === "kerberoscloud" && (
                    <div className={"block"}>
                      <div className={"title"}>
                        <Icon label={"lock"} />
                        <h1>Kerberos Hub credentials</h1>
                      </div>
                      <p>
                        To connect your Kerberos Vault to your Kerberos Hub
                        installation,{" "}
                        <a href={"https://doc.kerberos.io"} target={"_blank"} rel={"noreferrer"}>
                          {" "}
                          you will need to obtain a Hub key
                        </a>{" "}
                        linked to your account.{" "}
                      </p>

                      <Input
                        label={"Hub key"}
                        placeholder={"Specify your Kerberos Hub key"}
                        readonly={false}
                        disabled={false}
                        type="password"
                        name="password"
                        iconleft=""
                        iconright="activity"
                        seperate
                        value={this.state.cloudkey}
                        error={this.state.errors["cloudkey"]}
                        onChange={(event) =>
                          this.changeHandler(event, "cloudkey")
                        }
                      />
                    </div>
                  )}
                </div>
              </ModalBody>
              <ModalFooter
                right={
                  <>
                    <Button
                      label={"Validate"}
                      icon={"verify"}
                      type={"success"}
                      onClick={() => this.validateIntegration()}
                    />
                    <Button
                      label={`${this.props.mode} integration`}
                      icon={
                        this.props.mode === "Add" ? "plus-circle" : "pencil"
                      }
                      disabled={
                        //!this.state.validated ||
                        //this.props.queueStatus !== "success" ||
                        //this.state.error
                        false
                      }
                      type={
                        //this.props.queueStatus === "success" &&
                        //this.state.validated &&
                        //!this.state.error ? "submit" : "disabled"
                        "submit"
                      }
                      buttonType={"submit"}
                    />
                  </>
                }
              />
            </form>
          </Modal>
        )}
        <span onClick={() => this.handleClickOpen()}>
          {this.props.children}
        </span>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  queueStatus: state.storage.queueStatus,
  queueError: state.storage.queueError,
});

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CRUDQueue);
