import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ListSubscriptions from '../../components/ListSubscriptions';
import { getSubscriptionSettings } from '../../actions';
import './Subscriptions.css';
import 'typeface-roboto';

class Subscriptions extends React.Component {

  componentDidMount() {
    this.props.dispatchGetSubscriptions();
  }

  render() {
    return <div>
          <div className="actions">
             { /* <AddContainer
              addContainer={this.props.dispatchAddContainer} /> */ }
          </div>
          <div className="list">
            <ListSubscriptions
              subscriptions={this.props.subscriptions} />
          </div>
      </div>
  }
}

const mapStateToProps = (state, ownProps) => ({
  subscriptions: state.cloud.subscriptionsettings,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatchGetSubscriptions: () => dispatch(getSubscriptionSettings()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Subscriptions));
