import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CRUDQueue from "../../components/Queue/CRUDQueue";
import ListQueues from "../../components/Queue/ListQueues";
import {
  getQueue,
  testQueue,
  addQueue,
  editQueue,
  removeQueue,
} from "../../actions";
import "./Queues.scss";
import "typeface-roboto";
import {
  Breadcrumb,
  Button,
  ControlBar,
  InfoBox,
  Input,
  SetupBox,
} from "@kerberos-io/ui";

class Queues extends React.Component {
  constructor() {
    super();
    this.state = {
      search: "",
    };

    this.getQueues = this.getQueues.bind(this);
    this.addQueue = this.addQueue.bind(this);
    this.validateQueue = this.validateQueue.bind(this);
    this.editQueue = this.editQueue.bind(this);
    this.removeQueue = this.removeQueue.bind(this);
    this.filterIntegrations = this.filterIntegrations.bind(this);
  }

  componentDidMount() {
    this.getQueues();
  }

  getQueues() {
    this.props.dispatchGetQueues();
  }

  addQueue(data) {
    this.props.dispatchAddQueue(data, this.getQueues);
  }

  validateQueue(data) {
    this.props.dispatchTestQueue(data, () => {});
  }

  editQueue(data) {
    this.props.dispatchEditQueue(data, this.getQueues);
  }

  removeQueue(data) {
    this.props.dispatchRemoveQueue(data.id, this.getQueues);
  }

  filterIntegrations(e) {
    const { value } = e.target;
    this.setState({
      search: value,
    });
  }

  render() {
    const { search } = this.state;
    const queues = this.props.queues
      ? this.props.queues.filter((q) => q.name.indexOf(search) >= 0)
      : [];
    return (
      <div>
        <Breadcrumb
          title={"Integrations"}
          level1={"Integrate with your own business processes"}
          level1Link={""}
        >
          <CRUDQueue
            mode="Add"
            onSubmit={this.addQueue}
            onValidate={this.validateQueue}
          >
            <Button
              label={"Add Integration"}
              icon={"plus-circle"}
              type={"default"}
            />
          </CRUDQueue>
        </Breadcrumb>
        <ControlBar>
          <Input
            iconleft="search"
            onChange={this.filterIntegrations}
            placeholder="Search integrations..."
            layout={"controlbar"}
            type="text"
          />
        </ControlBar>
        <div className={"info"}>
          <InfoBox
            image={"info-professor"}
            title={"Integrate with your apps"}
            description={
              "Having your recordings stored in one of your Storage Providers is great, however recordings are just data until you start consuming and processing them to discover and create useful insights. With integrations you setup one or more connectors to receive real-time events and/or send them to our Kerberos Hub solution for visualisation or another Kerberos Vault for cloud storage. Missing your Integration?"
            }
          ></InfoBox>
        </div>
        {queues.length > 0 && (
          <ListQueues
            getQueues={this.getQueues}
            editQueue={this.editQueue}
            validateQueue={this.validateQueue}
            removeQueue={this.removeQueue}
            queues={queues}
          />
        )}

        {queues.length === 0 && (
          <SetupBox
            dashed={true}
            header={"Add your integration"}
            text={
              "It looks like you didn't add an integration so far, or the integration you are looking for doesn't exists."
            }
            btnicon={"counting"}
            button={
              <CRUDQueue mode="Add" onSubmit={this.addQueue}>
                <Button
                  label={"Add Integration"}
                  icon={"plus-circle"}
                  type={"default"}
                  onValidate={this.validateQueue}
                />
              </CRUDQueue>
            }
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  queues: state.storage.queues,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatchGetQueues: () => dispatch(getQueue()),
  dispatchTestQueue: (data, success) => dispatch(testQueue(data, success)),
  dispatchAddQueue: (data, success) => dispatch(addQueue(data, success)),
  dispatchEditQueue: (data, success) => dispatch(editQueue(data, success)),
  dispatchRemoveQueue: (id, success) => dispatch(removeQueue(id, success)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Queues));
