import React from "react";
import {
  Table,
  TableHeader,
  TableRow,
  TableBody,
  Badge,
  Ellipse,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "@kerberos-io/ui";
import CRUDQueue from "./CRUDQueue";
import "./ListQueues.scss";

const QueueColors = {
  sqs: "color-yellow",
  kafka: "color-gray",
  kerberoscloud: "hub",
};

export class ListQueues extends React.Component {
  constructor() {
    super();
    this.state = {
      openConfirm: false,
      currentQueue: null,
      confirmCallback: null,
      integration: "",
    };
    this.removeQueue = this.removeQueue.bind(this);
    this.editQueue = this.editQueue.bind(this);
    this.validateQueue = this.validateQueue.bind(this);
    this.closeConfirm = this.closeConfirm.bind(this);
  }

  removeQueue(q) {
    this.setState({
      openConfirm: true,
      currentQueue: q,
      confirmCallback: () => {
        this.props.removeQueue(q);
        this.closeConfirm();
      },
    });
  }

  editQueue(q) {
    this.props.editQueue(q);
  }

  validateQueue(q) {
    this.props.validateQueue(q);
  }

  closeConfirm() {
    this.setState({
      openConfirm: false,
      confirmCallback: null,
    });
  }

  changeHandler(event, field) {
    this.setState({
      [field]: event.target.value,
    });
  }

  canBeRemoved() {
    return this.state.integration === this.state.currentQueue.name;
  }

  render() {
    const queues = this.props.queues;
    return (
      <div className={"list-queues"}>
        <Table>
          <TableHeader>
            <TableRow headercells={["active", "integration", "", ""]} />
          </TableHeader>
          <TableBody>
            {queues &&
              queues.map((q) => (
                <TableRow
                  key={q.name}
                  bodycells={[
                    <Badge
                      title={q.enabled === "true" ? "enabled" : "disabled"}
                      status={q.enabled === "true" ? "success" : "secondary"}
                    />,
                    <span className={"bundle-fields"}>
                      <Ellipse status={QueueColors[q.queue]} />
                      <b>{q.name}</b>
                      <p>({q.queue})</p>
                    </span>,
                    <span></span>,
                    <>
                      <CRUDQueue
                        mode="Edit"
                        queue={q}
                        onSubmit={this.editQueue}
                        onValidate={this.validateQueue}
                      >
                        <Button type="outlined" icon="pencil" label="" />
                      </CRUDQueue>
                      <span className={"delete"}>
                        <Button
                          onClick={() => this.removeQueue(q)}
                          type="outlined"
                          icon="trush"
                          label=""
                        />
                      </span>
                    </>,
                  ]}
                />
              ))}
          </TableBody>
        </Table>

        {this.state.openConfirm && (
          <Modal maxWidth={"600"}>
            <ModalHeader
              onClose={() => this.closeConfirm()}
              title={"Are you sure you want to delete?"}
            />
            <ModalBody>
              By removing the integration <b>{this.state.currentQueue.name}</b>,
              the integration will no longer work, and the connection settings
              will be lost.
              <Input
                placeholder={`Type the name "${this.state.currentQueue.name}" of the integration, you would like to remove.`}
                type={"text"}
                onChange={(event) => this.changeHandler(event, "integration")}
              />
            </ModalBody>
            <ModalFooter
              right={
                <>
                  <Button
                    disabled={!this.canBeRemoved()}
                    onClick={this.canBeRemoved() && this.state.confirmCallback}
                    label={"Delete"}
                    icon={"trush"}
                    type={this.canBeRemoved() ? "submit" : "neutral"}
                    buttontype={"submit"}
                  />
                </>
              }
            />
          </Modal>
        )}
      </div>
    );
  }
}

export default ListQueues;
