import React from "react";
import {
  Main,
  MainBody,
  Gradient,
  Sidebar,
  Navigation,
  NavigationSection,
  NavigationItem,
  NavigationGroup,
  Profilebar,
} from "@kerberos-io/ui";
import "./App.scss";
import logo from "./header-minimal-logo-36x36.svg";
import "@kerberos-io/ui/lib/index.css";
import { logout } from "./actions";
import { connect } from "react-redux";
import config from "./config";

class App extends React.Component {
  render() {
    return (
      <div id={"page-root"}>
        <Sidebar
          logo={logo}
          title="Kerberos Vault"
          version={config.VERSION}
          mobile={true}
        >
          <Profilebar
            username={this.props.username}
            email={"support@kerberos.io"}
            userrole={"admin"}
            logout={this.props.dispatchLogout}
          />
          <Navigation>
            <NavigationSection title={"monitoring"} />
            <NavigationGroup>
              <NavigationItem
                title={"Dashboard"}
                icon={"dashboard"}
                link={"dashboard"}
              />
              <NavigationItem title={"Media"} icon={"media"} link={"media"} />
              <NavigationItem
                title={"Cameras"}
                icon={"cameras"}
                link={"cameras"}
              />
              {/*<NavigationItem
                title={"Vaults"}
                icon={"cameras"}
                link={"vaults"}
              />
              <NavigationItem
                title={"Activity log"}
                icon={"activity"}
                link={"activity"}
              /> */}
            </NavigationGroup>
            <NavigationSection title={"management"} />
            <NavigationGroup>
              <NavigationItem
                title={"Storage Providers"}
                icon={"cloud"}
                link={"providers"}
              />
              <NavigationItem
                title={"Integrations"}
                icon={"counting"}
                link={"queues"}
              />
              <NavigationItem
                title={"Accounts"}
                icon={"accounts"}
                link={"accounts"}
              />
            </NavigationGroup>
            <NavigationSection title={"help & support"} />
            <NavigationGroup>
              <NavigationItem
                title={"Swagger API docs"}
                icon={"api"}
                external={true}
                link={config.API_URL + "swagger/index.html"}
              />
              <NavigationItem
                title={"Documentation"}
                icon={"book"}
                external={true}
                link={"https://doc.kerberos.io/vault/first-things-first"}
              />
              <NavigationItem
                title={"Github"}
                icon={"github-nav"}
                external={true}
                link={"https://github.com/kerberos-io/vault"}
              />
            </NavigationGroup>
          </Navigation>
        </Sidebar>
        <Main>
          <Gradient />
          <a href="https://app.kerberos.io" target="_blank" rel="noreferrer"><div class="cloud-not-installed"><div><svg class="icon" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.8843 5C5.81855 5 4.12306 6.61291 3.95576 8.66459L3.89801 9.37283L3.21984 9.55041C2.52063 9.7335 1.97107 10.4101 1.97107 11.25C1.97107 12.2457 2.73484 13 3.61364 13L3.62312 13L3.6288 12.9999L7.8673 13L7.8843 13L7.9013 13L7.90539 12.9999L13.2412 13L13.25 13C14.6126 13 15.7686 11.8353 15.7686 10.3333C15.7686 8.83132 14.6126 7.66664 13.25 7.66664C13.0174 7.66664 12.7936 7.69976 12.5818 7.7613L11.7891 7.99158L11.4258 7.24051C10.783 5.91143 9.4376 5 7.8843 5ZM2.07896 7.854C2.60645 5.08806 5.00462 3 7.8843 3C9.94489 3 11.7579 4.06955 12.8157 5.68827C12.9588 5.67396 13.1037 5.66664 13.25 5.66664C15.758 5.66664 17.7397 7.78521 17.7397 10.3333C17.7397 12.8814 15.758 15 13.25 15L13.2378 15H7.90543L7.8843 15L7.86321 15H3.62895L3.61364 15C1.58951 15 0 13.2918 0 11.25C0 9.76155 0.840048 8.45714 2.07896 7.854Z" fill="currentColor"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M4.54775 4.04634C5.73804 3.22094 7.1845 2.86521 8.61514 3.04603C10.0458 3.22685 11.362 3.93177 12.3164 5.02823C13.2707 6.1247 13.7974 7.53716 13.7974 9.00001C13.7974 9.5523 13.3562 10 12.8119 10C12.2676 10 11.8264 9.5523 11.8264 9.00001C11.8264 8.02478 11.4752 7.08314 10.839 6.35216C10.2028 5.62119 9.32526 5.15124 8.3715 5.03069C7.41774 4.91014 6.45343 5.1473 5.65991 5.69756C4.86638 6.24783 4.29832 7.07329 4.06253 8.01873C3.929 8.55414 3.393 8.87834 2.86534 8.74285C2.33767 8.60736 2.01816 8.06349 2.15169 7.52808C2.50537 6.10993 3.35747 4.87174 4.54775 4.04634Z" fill="currentColor"></path></svg>Activate Kerberos Hub, and make your cameras and recordings available through a secured cloud!</div></div></a>
          <MainBody>{this.props.children}</MainBody>
        </Main>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  username: state.auth.username,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatchLogout: () => dispatch(logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
