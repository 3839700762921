import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import docker from './docker';
import auth from './auth';
import cloud from './cloud';
import storage from './storage';

function Reducers (history) {
  return  combineReducers({
    docker,
    auth,
    cloud,
    storage,
    router: connectRouter(history),
});
}

export default Reducers;
