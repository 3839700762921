import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ListMedia from "../../components/Media/ListMedia";
import { getMedia, getAccount } from "../../actions";
import TablePagination from "@material-ui/core/TablePagination";
import "./Media.css";
import "typeface-roboto";
import { Breadcrumb, Button } from "@kerberos-io/ui";
import Grid from "@material-ui/core/Grid";

class Media extends React.Component {
  constructor() {
    super();
    this.state = {
      page: 0,
      rows: 50,
    };
    this.refresh = this.refresh.bind(this);
    this.updateData = this.updateData.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
  }

  componentDidMount() {
    const { rows, page } = this.state;
    this.updateData(rows, page);
    this.getAccounts();
  }

  getAccounts() {
    this.props.dispatchGetAccounts();
  }

  handleChangePage(event, page) {
    this.setState({
      page,
    });
    this.updateData(this.state.rows, page);
  }

  handleChangeRowsPerPage(event) {
    const rows = parseInt(event.target.value, 10);
    this.setState({
      rows,
    });
    this.updateData(rows, this.state.page);
  }

  updateData(rows, page) {
    this.props.dispatchGetMedia({
      rows,
      page,
    });
  }

  refresh() {
    const { rows, page } = this.state;
    this.updateData(rows, page);
  }

  render() {
    return (
      <>
        <Breadcrumb
          title={"Media"}
          level1={"Review all the recordings being uploaded."}
          level1Link={""}
        >
          <Button
            label={"Refresh"}
            icon={"refresh"}
            type={"neutral"}
            onClick={this.refresh}
          />
        </Breadcrumb>
        <TablePagination
          component="div"
          count={this.props.mediaCount || 0}
          page={this.state.page}
          onChangePage={this.handleChangePage}
          rowsPerPage={this.state.rows}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          showFirstButton={true}
          showLastButton={true}
        />
        <Grid item xs={12} md={12} lg={12}>
          <ListMedia
            refresh={this.refresh}
            media={this.props.media}
            accounts={this.props.accounts}
          />
        </Grid>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  media: state.storage.media,
  mediaCount: state.storage.mediaCount,
  accounts: state.storage.accounts,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatchGetAccounts: () => dispatch(getAccount()),
  dispatchGetMedia: (pagination) => dispatch(getMedia(pagination)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Media));
