import {
  doGetUsers,
  doGetSubscriptionSettings,
} from '../api/cloud';

export const getUsers = (pagination, onSuccess, onError) => {
  return function (dispatch) {
    doGetUsers(pagination, (data) => {
      dispatch({
        type: 'GET_USERS',
        users: data.data,
        usersCount: data.count,
      });
      if(onSuccess) {
        onSuccess();
      }
    }, () => {
      if(onError) {
        onError();
      }
    });
  }
}

export const getSubscriptionSettings = (onSuccess, onError) => {
  return function (dispatch) {
    doGetSubscriptionSettings((data) => {
      dispatch({
        type: 'GET_SUBSCRIPTION_SETTINGS',
        subscriptionsettings: data.data
      });
      if(onSuccess) {
        onSuccess();
      }
    }, () => {
      if(onError) {
        onError();
      }
    });
  }
}
