import React from "react";
import CRUDStorageProvider from "./CRUDStorageProvider";
import "./ListStorageProviders.css";
import {
  Table,
  TableHeader,
  TableRow,
  TableBody,
  Badge,
  Ellipse,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "@kerberos-io/ui";

const ProviderColors = {
  gcp: "color-yellow",
  minio: "color-gray",
  storj: "color-gray",
  aws: "color-red",
};

export class ListStorageProviders extends React.Component {
  constructor() {
    super();
    this.state = {
      openConfirm: false,
      currentStorageProvider: null,
      confirmCallback: null,
      provider: "",
    };

    this.removeStorageProvider = this.removeStorageProvider.bind(this);
    this.editStorageProvider = this.editStorageProvider.bind(this);
    this.validateStorageProvider = this.validateStorageProvider.bind(this);
    this.closeConfirm = this.closeConfirm.bind(this);
  }

  removeStorageProvider(sp) {
    this.setState({
      openConfirm: true,
      currentStorageProvider: sp,
      confirmCallback: () => {
        this.props.removeStorageProvider(sp);
        this.closeConfirm();
      },
    });
  }

  validateStorageProvider(sp) {
    this.props.validateStorageProvider(sp);
  }

  editStorageProvider(sp) {
    this.props.editStorageProvider(sp);
  }

  closeConfirm() {
    this.setState({
      openConfirm: false,
      confirmCallback: null,
    });
  }

  changeHandler(event, field) {
    this.setState({
      [field]: event.target.value,
    });
  }

  canBeRemoved() {
    return this.state.provider === this.state.currentStorageProvider.name;
  }

  render() {
    const providers = this.props.providers;
    return (
      <div>
        <Table>
          <TableHeader>
            <TableRow headercells={["active", "provider", "bucket", ""]} />
          </TableHeader>
          <TableBody>
            {providers &&
              providers.map((p) => (
                <TableRow
                  key={p.name}
                  bodycells={[
                    <Badge
                      title={p.enabled === "true" ? "enabled" : "disabled"}
                      status={p.enabled === "true" ? "success" : "secondary"}
                    />,
                    <span className={"bundle-fields"}>
                      <Ellipse status={ProviderColors[p.provider]} />
                      <b>{p.name}</b>
                      <p>({p.provider})</p>
                    </span>,
                    <span className={"bundle-fields"}>
                      <span>{p.bucket}</span>
                    </span>,
                    <>
                      <CRUDStorageProvider
                        mode="Edit"
                        storageprovider={p}
                        onSubmit={this.editStorageProvider}
                        onValidate={this.validateStorageProvider}
                      >
                        <Button type="outlined" icon="pencil" label="" />
                      </CRUDStorageProvider>
                      <span className={"delete"}>
                        <Button
                          onClick={() => this.removeStorageProvider(p)}
                          type="outlined"
                          icon="trush"
                          label=""
                        />
                      </span>
                    </>,
                  ]}
                />
              ))}
          </TableBody>
        </Table>

        {this.state.openConfirm && (
          <Modal maxWidth={"600"}>
            <ModalHeader
              onClose={() => this.closeConfirm()}
              title={"Are you sure you want to delete?"}
            />
            <ModalBody>
              By removing the storage provider{" "}
              <b>{this.state.currentStorageProvider.name}</b>, the integration
              will no longer work, and the connection settings will be lost.
              <Input
                placeholder={`Type the name "${this.state.currentStorageProvider.name}" of the integration, you would like to remove.`}
                type={"text"}
                onChange={(event) => this.changeHandler(event, "provider")}
              />
            </ModalBody>
            <ModalFooter
              right={
                <>
                  <Button
                    disabled={!this.canBeRemoved()}
                    onClick={this.canBeRemoved() && this.state.confirmCallback}
                    label={"Delete"}
                    icon={"trush"}
                    type={this.canBeRemoved() ? "submit" : "neutral"}
                    buttontype={"submit"}
                  />
                </>
              }
            />
          </Modal>
        )}
      </div>
    );
  }
}

export default ListStorageProviders;
