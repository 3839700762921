const cloud = (state = {
  users: [],
  usersCount: 0,
  subscriptionsettings: null,
}, action) => {
  switch (action.type) {
    case 'GET_USERS':
      return {
        ...state,
        users: action.users,
        usersCount: action.usersCount,
      };
    case 'GET_SUBSCRIPTION_SETTINGS':
      return {
        ...state,
        subscriptionsettings: action.subscriptionsettings
      };
    default:
      return state
  }
}

export default cloud;
