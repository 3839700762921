import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  getQueue,
  getStorageProvider,
  getAccount,
  getForwards,
  getMedia,
  getCameras
} from '../../actions';
import './Dashboard.css';
import 'typeface-roboto';
import {Breadcrumb, Button, KPI} from "@kerberos-io/ui";

class Dashboard extends React.Component {

  constructor() {
    super();
    this.getQueues = this.getQueues.bind(this);
    this.getStorageProviders = this.getStorageProviders.bind(this);
    this.getForwards = this.getForwards.bind(this);
    this.getAccounts = this.getAccounts.bind(this);
    this.getMedia = this.getMedia.bind(this);
    this.getCameras = this.getCameras.bind(this);
    this.refresh = this.refresh.bind(this);
  }
  
  componentDidMount() {
    this.refresh();
  }

  refresh(){
    this.getQueues();
    this.getForwards();
    this.getStorageProviders();
    this.getAccounts();
    this.getMedia();
    this.getCameras();
  }

  getQueues(){
    this.props.dispatchGetQueues();
  }

  getStorageProviders(){
    this.props.dispatchGetProviders();
  }

  getForwards(){
    this.props.dispatchGetForwards();
  }

  getAccounts(){
    this.props.dispatchGetAccounts();
  }

  getCameras(){
    this.props.dispatchGetCameras();
  }

  getMedia(){
    const rows = 50;
    const page = 0;
    this.props.dispatchGetMedia({
      rows,
      page,
    });
  }

  render() {
    return <div>
          <Breadcrumb title={"Dashboard"} level1={"An overview of your Kerberos Vault."} level1Link={""}>
            <Button onClick={this.refresh} label={"Refresh"} icon={"refresh"} type={"neutral"}/>
          </Breadcrumb>
          <div className="stats grid-container --four-columns">
            <KPI number={this.props.providers && this.props.providers.length} divider={"0"} footer={"Number of providers"}/>
            <KPI number={this.props.queues && this.props.queues.length} divider={"0"} footer={"Number of integrations"}/>
            <KPI number={this.props.accounts && this.props.accounts.length} divider={"0"} footer={"Number of accounts"}/>
            <KPI number={this.props.mediaCount} divider={"0"} footer={"Number of recordings"}/>
            <KPI number={this.props.cameras ? this.props.cameras.length : 0} divider={"0"} footer={"Number of cameras"}/>
          </div>
      </div>
  }
}

const mapStateToProps = (state, ownProps) => ({
  providers: state.storage.providers,
  queues: state.storage.queues,
  forwards: state.storage.forwards,
  accounts: state.storage.accounts,
  media: state.storage.media,
  mediaCount: state.storage.mediaCount,
  cameras: state.storage.cameras,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatchGetProviders: () => dispatch(getStorageProvider()),
  dispatchGetQueues: () => dispatch(getQueue()),
  dispatchGetForwards: () => dispatch(getForwards()),
  dispatchGetAccounts: () => dispatch(getAccount()),
  dispatchGetCameras: () => dispatch(getCameras()),
  dispatchGetMedia: (pagination) => dispatch(getMedia(pagination)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
