import React from "react";
import config from "../../config";
import {
    Block,
    Button,
    BlockHeader,
    BlockBody,
    BlockFooter,
    AlertMessage,
    Input,
    Icon,
    LandingLayout
} from "@kerberos-io/ui";
import {login, resetLogin} from '../../actions';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import './Login.scss';

class Login extends React.Component {


    handleSubmit = (event) => {
        event.preventDefault()
        const {target} = event;
        const data = new FormData(target);
        this.props.dispatchLogin(data.get('username'), data.get('password'));
    }

    hideMessage = () => {
        this.props.dispatchResetLogin();
    }

    render() {

        const {loginError, error} = this.props;

        return (

            <LandingLayout title="Kerberos Vault" version={config.VERSION}
                           description="Bring your own Storage, extend and integrate with your landscape.">
                <section className="login-body">
                    <Block>
                        <form onSubmit={this.handleSubmit} noValidate>
                            <BlockHeader>
                                <div>
                                    <Icon label="login"/> <h4>Login</h4>
                                </div>
                            </BlockHeader>
                            {loginError && <AlertMessage message={error} onClick={() => this.hideMessage()}/>}
                            <BlockBody>
                                <Input
                                    label={"username or email"}
                                    placeholder={"Your username/email"}
                                    readonly={false}
                                    disabled={false}
                                    type="text"
                                    name="username"
                                    iconleft="accounts"
                                />
                                <Input
                                    label={"password"}
                                    placeholder={"Your password"}
                                    readonly={false}
                                    disabled={false}
                                    type="password"
                                    name="password"
                                    iconleft="locked"
                                    iconright="activity"
                                    seperate
                                />
                            </BlockBody>
                            <BlockFooter>
                                <p></p>
                                <Button buttonType="submit" type="submit" icon="logout" label="Login"/>
                            </BlockFooter>
                        </form>
                    </Block>
                </section>
            </LandingLayout>
        );
    }
}

const mapStateToProps = (state) => ({
    loginError: state.auth.loginError,
    error: state.auth.error,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchLogin: (username, password) => {
        dispatch(login(username, password));
    },
    dispatchResetLogin: () => {
        dispatch(resetLogin());
    },
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Login)
);

