const hostname = window.location.hostname;
const protocol = window.location.protocol;

const dev = {
  ENV: "dev",
  HOSTNAME: hostname,
  API_URL: `${protocol}//${hostname}:8081/`,
};

const version = "v1.3.12";

const staging = {
  VERSION: version,
  ENV: "staging",
  HOSTNAME: "staging.api.vault.kerberos.live",
  API_URL: "https://staging.api.vault.kerberos.live",
};

const env = window["env"];
const prod = {
  VERSION: version,
  ENV: "prod",
  HOSTNAME: hostname,
  API_URL:
    env && env["apiUrl"] !== ""
      ? `${protocol}//${window["env"]["apiUrl"]}/`
      : `${protocol}//api.${hostname}/`,
};

let config = dev;
if (process.env.REACT_APP_STAGE === "production") {
  config = prod;
} else if (process.env.REACT_APP_STAGE === "staging") {
  config = staging;
}

export default config;
