import {
  doGetStorageProvider,
  doAddStorageProvider,
  doTestStorageProvider,
  doEditStorageProvider,
  doRemoveStorageProvider,
  doGetQueue,
  doTestQueue,
  doAddQueue,
  doEditQueue,
  doRemoveQueue,
  doGetAccount,
  doAddAccount,
  doEditAccount,
  doRemoveAccount,
  doGetForwards,
  doAddForwards,
  doEditForwards,
  doRemoveForwards,
  doGetMedia,
  doGetCameras,
  doRemoveCamera,
} from "../api/storage";

export const getMedia = (pagination, onSuccess, onError) => {
  return function (dispatch) {
    doGetMedia(
      pagination,
      (data) => {
        dispatch({
          type: "GET_MEDIA",
          media: data.data,
          mediaCount: data.count,
        });
        if (onSuccess) {
          onSuccess();
        }
      },
      () => {
        if (onError) {
          onError();
        }
      }
    );
  };
};

export const getStorageProvider = (onSuccess, onError) => {
  return function (dispatch) {
    doGetStorageProvider(
      (data) => {
        dispatch({
          type: "GET_STORAGE_PROVIDER",
          providers: data.data,
        });
        if (onSuccess) {
          onSuccess();
        }
      },
      () => {
        if (onError) {
          onError();
        }
      }
    );
  };
};

export const testStorageProvider = (data, onSuccess, onError) => {
  return function (dispatch) {
    dispatch({
      type: "TEST_STORAGE_PROVIDER",
    });
    doTestStorageProvider(
      data,
      (d) => {
        dispatch({
          type: "TEST_STORAGE_PROVIDER_SUCCESS",
          data: d.data,
        });
        if (onSuccess) {
          onSuccess();
        }
      },
      (error) => {
        dispatch({
          type: "TEST_STORAGE_PROVIDER_ERROR",
          error,
        });
        if (onError) {
          onError();
        }
      }
    );
  };
};

export const addStorageProvider = (data, onSuccess, onError) => {
  return function (dispatch) {
    doAddStorageProvider(
      data,
      (d) => {
        dispatch({
          type: "ADD_STORAGE_PROVIDER",
          data: d.data,
        });
        if (onSuccess) {
          onSuccess();
        }
      },
      () => {
        if (onError) {
          onError();
        }
      }
    );
  };
};

export const editStorageProvider = (data, onSuccess, onError) => {
  return function (dispatch) {
    doEditStorageProvider(
      data,
      (d) => {
        dispatch({
          type: "EDIT_STORAGE_PROVIDER",
          data: d.data,
        });
        if (onSuccess) {
          onSuccess();
        }
      },
      () => {
        if (onError) {
          onError();
        }
      }
    );
  };
};

export const removeStorageProvider = (id, onSuccess, onError) => {
  return function (dispatch) {
    doRemoveStorageProvider(
      id,
      (data) => {
        dispatch({
          type: "REMOVE_STORAGE_PROVIDER",
          id,
        });
        if (onSuccess) {
          onSuccess();
        }
      },
      () => {
        if (onError) {
          onError();
        }
      }
    );
  };
};

export const getQueue = (onSuccess, onError) => {
  return function (dispatch) {
    doGetQueue(
      (data) => {
        dispatch({
          type: "GET_QUEUE",
          queues: data.data,
        });
        if (onSuccess) {
          onSuccess();
        }
      },
      () => {
        if (onError) {
          onError();
        }
      }
    );
  };
};

export const testQueue = (data, onSuccess, onError) => {
  return function (dispatch) {
    dispatch({
      type: "TEST_QUEUE",
    });
    doTestQueue(
      data,
      (d) => {
        dispatch({
          type: "TEST_QUEUE_SUCCESS",
          data: d.data,
        });
        if (onSuccess) {
          onSuccess();
        }
      },
      (error) => {
        dispatch({
          type: "TEST_QUEUE_ERROR",
          error,
        });
        if (onError) {
          onError();
        }
      }
    );
  };
};

export const addQueue = (data, onSuccess, onError) => {
  return function (dispatch) {
    doAddQueue(
      data,
      (d) => {
        dispatch({
          type: "ADD_QUEUE",
          data: d.data,
        });
        if (onSuccess) {
          onSuccess();
        }
      },
      () => {
        if (onError) {
          onError();
        }
      }
    );
  };
};

export const editQueue = (data, onSuccess, onError) => {
  return function (dispatch) {
    doEditQueue(
      data,
      (d) => {
        dispatch({
          type: "EDIT_QUEUE",
          data: d.data,
        });
        if (onSuccess) {
          onSuccess();
        }
      },
      () => {
        if (onError) {
          onError();
        }
      }
    );
  };
};

export const removeQueue = (id, onSuccess, onError) => {
  return function (dispatch) {
    doRemoveQueue(
      id,
      (data) => {
        dispatch({
          type: "REMOVE_QUEUE",
          id,
        });
        if (onSuccess) {
          onSuccess();
        }
      },
      () => {
        if (onError) {
          onError();
        }
      }
    );
  };
};

export const getForwards = (onSuccess, onError) => {
  return function (dispatch) {
    doGetForwards(
      (data) => {
        dispatch({
          type: "GET_FORWARDS",
          forwards: data.data,
        });
        if (onSuccess) {
          onSuccess();
        }
      },
      () => {
        if (onError) {
          onError();
        }
      }
    );
  };
};

export const addForwards = (data, onSuccess, onError) => {
  return function (dispatch) {
    doAddForwards(
      data,
      (d) => {
        dispatch({
          type: "ADD_FORWARD",
          data: d.data,
        });
        if (onSuccess) {
          onSuccess();
        }
      },
      () => {
        if (onError) {
          onError();
        }
      }
    );
  };
};

export const editForwards = (data, onSuccess, onError) => {
  return function (dispatch) {
    doEditForwards(
      data,
      (d) => {
        dispatch({
          type: "EDIT_FORWARD",
          data: d.data,
        });
        if (onSuccess) {
          onSuccess();
        }
      },
      () => {
        if (onError) {
          onError();
        }
      }
    );
  };
};

export const removeForwards = (id, onSuccess, onError) => {
  return function (dispatch) {
    doRemoveForwards(
      id,
      (data) => {
        dispatch({
          type: "REMOVE_FORWARD",
          id,
        });
        if (onSuccess) {
          onSuccess();
        }
      },
      () => {
        if (onError) {
          onError();
        }
      }
    );
  };
};

export const getAccount = (onSuccess, onError) => {
  return function (dispatch) {
    doGetAccount(
      (data) => {
        dispatch({
          type: "GET_ACCOUNT",
          accounts: data.data,
        });
        if (onSuccess) {
          onSuccess();
        }
      },
      () => {
        if (onError) {
          onError();
        }
      }
    );
  };
};

export const addAccount = (data, onSuccess, onError) => {
  return function (dispatch) {
    doAddAccount(
      data,
      (d) => {
        dispatch({
          type: "ADD_ACCOUNT",
          data: d.data,
        });
        if (onSuccess) {
          onSuccess();
        }
      },
      () => {
        if (onError) {
          onError();
        }
      }
    );
  };
};

export const editAccount = (data, onSuccess, onError) => {
  return function (dispatch) {
    doEditAccount(
      data,
      (d) => {
        dispatch({
          type: "EDIT_ACCOUNT",
          data: d.data,
        });
        if (onSuccess) {
          onSuccess();
        }
      },
      () => {
        if (onError) {
          onError();
        }
      }
    );
  };
};

export const removeAccount = (id, onSuccess, onError) => {
  return function (dispatch) {
    doRemoveAccount(
      id,
      (data) => {
        dispatch({
          type: "REMOVE_ACCOUNT",
          id,
        });
        if (onSuccess) {
          onSuccess();
        }
      },
      () => {
        if (onError) {
          onError();
        }
      }
    );
  };
};

export const getCameras = (onSuccess, onError) => {
  return function (dispatch) {
    doGetCameras(
      (data) => {
        dispatch({
          type: "GET_CAMERAS",
          cameras: data,
        });
        if (onSuccess) {
          onSuccess();
        }
      },
      () => {
        if (onError) {
          onError();
        }
      }
    );
  };
};

export const removeCamera = (id, onSuccess, onError) => {
  return function (dispatch) {
    doRemoveCamera(
      id,
      (data) => {
        dispatch({
          type: "REMOVE_CAMERA",
          id,
        });
        if (onSuccess) {
          onSuccess();
        }
      },
      () => {
        if (onError) {
          onError();
        }
      }
    );
  };
};
