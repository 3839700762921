import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import PropTypes from 'prop-types';

export default function RequireAuth(ComposedComponent) {
  class Authenticate extends React.Component {
    componentDidMount() {
      const { isAuthenticated, redirect } = this.props;
      if (!isAuthenticated) {
        redirect();
      }
    }
    render() {
      return (
        <div>
          { this.props.isAuthenticated ? <ComposedComponent {...this.props} /> : null }
        </div>
      );
    }
  }

  const mapStateToProps = (state) => {
    return {
      isAuthenticated: state.auth.loggedIn
    };
  };

  const mapDispatchToProps = dispatch => bindActionCreators({
    redirect: () => push('/login')
  }, dispatch)

  Authenticate.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    redirect: PropTypes.func.isRequired
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(Authenticate);
}
