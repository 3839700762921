import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import RefreshIcon from '@material-ui/icons/Refresh';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import './ListUsers.css';

export class ListUsers extends React.Component {

  constructor() {
    super();
    this.state = {
      container: null,
      hostname: "",
      port: "",
      open: false,
      openLogs: false,
      openConfirm: false,
      confirmCallback: null,
    };
  }

  componentDidMount() {
    this.removeDeployment = this.removeDeployment.bind(this);
    this.openContainer = this.openContainer.bind(this);
    this.openLogs = this.openLogs.bind(this);
    this.closeContainer = this.closeContainer.bind(this);
    this.closeLogs = this.closeLogs.bind(this);
    this.closeConfirm = this.closeConfirm.bind(this);
  }

  startContainer(id) {
    this.props.startContainer(id);
  }

  stopContainer(id) {
    this.props.stopContainer(id);
  }

  removeDeployment(id) {
    this.setState({
      openConfirm: true,
      confirmCallback: () => this.props.removeDeployment(id)
    });
  }

  openContainer(container, hostname, port) {
    this.setState({
      container,
      hostname,
      port,
      open: true,
    });
  }

  closeContainer() {
    this.setState({
      container: null,
      open: false,
    });
  }

  openLogs(container, port) {
    this.setState({
      container,
      port,
      openLogs: true,
    });
  }

  closeLogs() {
    this.setState({
      container: null,
      openLogs: false,
    });
  }

  closeConfirm() {
    this.setState({
      openConfirm: false,
      confirmCallback: null
    });
  }

  render() {
    const nowUnix = Math.round((new Date()).getTime() / 1000);
    return <div>
      <Paper className="root">
        <Table className="table">
          <TableHead>
            <TableRow>
              <TableCell>Username</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Subscription</TableCell>
              <TableCell># Devices</TableCell>
              <TableCell># Active Devices</TableCell>
              <TableCell>Reached Limit</TableCell>
              <TableCell>Active</TableCell>
              <TableCell>Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { this.props.users && this.props.users.map(u => <TableRow key={u.username}>
                <TableCell>{u.username}</TableCell>
                <TableCell>{u.email}</TableCell>
                <TableCell>{u.stripe_plan}</TableCell>
                <TableCell>{u.devices ? u.devices.length : 0}</TableCell>
                <TableCell>{u.devices ? u.devices.filter(d => d.analytics[0].timestamp + 180 >= nowUnix).length : 0}</TableCell>
                <TableCell>{u.reachedlimit ? <ClearIcon/> : ""}</TableCell>
                <TableCell>{u.isActive ? <CheckIcon/> : ""}</TableCell>
                <TableCell>{u.created_at}</TableCell>
              </TableRow>)
            }
          </TableBody>
        </Table>
      </Paper>

      <Tooltip title="Refresh deployments" placement="top">
        <Fab color="primary" aria-label="Refresh" className="refresh" onClick={this.props.getNodes}>
          <RefreshIcon/>
        </Fab>
      </Tooltip>
    </div>
  }
}

export default ListUsers;
