const docker = (state = {
  containers: [],
  deployments: [],
  nodes: [],
  pods: [],
  container: null,
  logs: null,
  cameras: [],
  global: null,
}, action) => {
  switch (action.type) {
    case 'GET_CONTAINERS':
      return {
        ...state,
        containers: action.containers
      };
    case 'GET_DEPLOYMENTS':
      return {
        ...state,
        deployments: action.deployments
      };
    case 'GET_GLOBAL_CONFIG':
      return {
        ...state,
        global: action.global
      };
    case 'GET_NODES':
      return {
        ...state,
        nodes: action.nodes
      };
    case 'GET_PODS':
      return {
        ...state,
        pods: action.pods
      };
    case 'GET_CONTAINER':
      return {
        ...state,
        container: action.data,
      };
    case 'GET_LOGS':
      return {
        ...state,
        logs: action.logs,
      };
    case 'CLEANUP_CONTAINER':
      return {
        ...state,
        container: null,
      };
    case 'CLEANUP_LOGS':
      return {
        ...state,
        logs: null,
      };
    case 'ADD_REGION':
      return {
        ...state,
        container: {
          ...state.container,
          custom: {
            ...state.container.custom,
            region: {
              ...state.container.custom.region,
              polygon: state.container.custom.region.polygon ? [{
                id: action.id,
                coordinates: action.polygon.points
              }, ...state.container.custom.region.polygon] :
              [{
                id: action.id,
                coordinates: action.polygon.points
              }]
            }
          }
        }
      };
    case 'REMOVE_REGION':
      return {
        ...state,
        container: {
          ...state.container,
          custom: {
            ...state.container.custom,
            region: {
              ...state.container.custom.region,
              polygon: state.container.custom.region.polygon.filter(c => c.id !== action.id),
            }
          }
        }
      };
    case 'UPDATE_REGION':
      return {
        ...state,
        container: {
          ...state.container,
          custom: {
            ...state.container.custom,
            region: {
              ...state.container.custom.region,
              polygon: state.container.custom.region.polygon.map(p => p.id === action.id ?
                { id: action.id, coordinates: action.polygon.points } :
                p
              )
            }
          }
        }
      };
    case 'UPDATE_CONTAINER':
      if(action.field === ''){
        return {
          ...state,
          container: {
            ...state.container,
            custom: action.value
          }
        };
      } else {
        const levels = action.field.split(".");
        if(levels.length === 1) {
          return {
            ...state,
            container: {
              ...state.container,
              custom: {
                ...state.container.custom,
                [action.field]: action.value
              }
            }
          };
        } else {
          return {
            ...state,
            container: {
              ...state.container,
              custom: {
                ...state.container.custom,
                [levels[0]]: {
                  ...state.container.custom[levels[0]],
                  [levels[1]]:action.value
                }
              }
            }
          };
        }
      }
    case 'UPDATE_GLOBAL_CONFIG':
    if(action.field === ''){
      return {
        ...state,
        global: action.value
      };
    } else {
      const levels = action.field.split(".");
      if(levels.length === 1) {
        return {
          ...state,
          global: {
            ...state.global,
            [action.field]: action.value
          }
        };
      } else {
        return {
          ...state,
          global: {
            ...state.global,
            [levels[0]]: {
              ...state.global[levels[0]],
              [levels[1]]:action.value
            }
          }
        };
      }
    }
    case 'START_CONTAINER':
      return {
        ...state,
        containers: state.containers.map(c => c.Id === action.id ?
          { ...c,
            ...action.data.data,
          } :
          c
        )
      };
    case 'STOP_CONTAINER':
      return {
        ...state,
        containers: state.containers.map(c => c.Id === action.id ?
          {
            ...c,
            ...action.data.data,
          } :
          c
        )
      };
    case 'REMOVE_CONTAINER':
      return {
        ...state,
        containers: state.containers.filter(c => c.Id !== action.id),
      };
    case 'ADD_CONTAINER':
      return {
        ...state,
        containers: [ action.data, ...state.containers ],
      };
    case 'DISCOVER_CAMERAS':
      return {
        ...state,
        cameras: [ ...action.cameras.filter(val => !state.cameras.includes(val)), ...state.cameras ],
      };
    default:
      return state
  }
}

export default docker;
