import React from "react";
import ReactTooltip from "react-tooltip";
import Moment from "react-moment";
import "./ListMedia.scss";
import "moment-timezone";
import { doGetMediaContent } from "../../api/storage";
import {
  Table,
  TableHeader,
  TableRow,
  TableBody,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  Icon,
} from "@kerberos-io/ui";

export class ListMedia extends React.Component {
  constructor() {
    super();
    this.state = {
      container: null,
      hostname: "",
      port: "",
      video: "",
      open: false,
      openLogs: false,
      openVideo: false,
      openConfirm: false,
      confirmCallback: null,
      openEvents: false,
      events: [],
    };
  }

  componentDidMount() {
    this.openVideo = this.openVideo.bind(this);
    this.closeVideo = this.closeVideo.bind(this);
    this.openEvents = this.openEvents.bind(this);
    this.closeEvents = this.closeEvents.bind(this);
    this.openFile = this.openFile.bind(this);
  }

  openFile(fileName, providerName, accountName, accounts) {
    if (
      fileName !== "" &&
      providerName !== "" &&
      accountName !== "" &&
      accounts &&
      accounts.length
    ) {
      const account = accounts.find((a) => a.account === accountName);
      if (account != null) {
        const urlToMedia = doGetMediaContent(fileName, providerName, account);
        window.open(urlToMedia, "_blank");
      }
    }
  }

  openVideo(fileName, providerName, accountName, accounts) {
    if (
      fileName !== "" &&
      providerName !== "" &&
      accountName !== "" &&
      accounts &&
      accounts.length
    ) {
      const account = accounts.find((a) => a.account === accountName);
      if (account != null) {
        const urlToMedia = doGetMediaContent(fileName, providerName, account);

        this.setState({
          video: urlToMedia,
          openVideo: true,
        });
      }
    }
  }

  closeVideo() {
    this.setState({
      video: "",
      openVideo: false,
    });
  }

  openEvents(events) {
    this.setState({
      events,
      openEvents: true,
    });
  }

  closeEvents() {
    this.setState({
      events: [],
      openEvents: false,
    });
  }

  truncate(fullStr, strLen, separator) {
    if (fullStr.length <= strLen) return fullStr;

    separator = separator || "...";

    const sepLen = separator.length,
      charsToShow = strLen - sepLen,
      frontChars = Math.ceil(charsToShow / 2),
      backChars = Math.floor(charsToShow / 2);

    return (
      fullStr.substr(0, frontChars) +
      separator +
      fullStr.substr(fullStr.length - backChars)
    );
  }

  render() {
    const media = this.props.media;
    return (
      <div className={"list-media"}>
        <Table>
          <TableHeader>
            <TableRow
              id={"header"}
              headercells={[
                "status",
                "timestamp",
                "account",
                "provider",
                "device",
                "filename",
                "",
              ]}
            />
          </TableHeader>
          <TableBody>
            {media &&
              media.map((m) => (
                <TableRow
                  id={m.timestamp + "-" + m.provider + "-" + m.device}
                  key={m.timestamp + "-" + m.provider + "-" + m.device}
                  bodycells={[
                    <span
                      className={"bundle-fields"}
                      onClick={() => this.openEvents(m.events)}
                    >
                      {m.finished && (
                        <Badge title={"uploaded"} status={"success"} />
                      )}
                      {!m.finished && m.to_be_forwarded && (
                        <Badge title={"in forwarding"} status={"secondary"} />
                      )}
                      {m.finished && m.forwarded && (
                        <Badge title={"forwarded"} status={"success"} />
                      )}
                      {!m.finished && !m.uploaded && (
                        <Badge title={"error"} status={"alert"} />
                      )}
                    </span>,
                    <span className={"bundle-fields"}>
                      <p>
                        <Moment format="YYYY/MM/DD HH:mm:ss" unix>
                          {m.timestamp}
                        </Moment>
                      </p>
                    </span>,
                    <span className={"bundle-fields"}>
                      <p>{m.account}</p>
                    </span>,
                    <span className={"bundle-fields"}>
                      <p>{m.provider}</p>
                    </span>,
                    <span className={"bundle-fields"}>
                      <p>
                        {m.device.length > 5
                          ? m.device.substring(0, 5)
                          : m.device}
                      </p>
                    </span>,
                    <span className={"bundle-fields break-long-field"}>
                      <span data-tip={m.filename}>
                        {this.truncate(m.filename, 40, "..")}
                      </span>
                      <ReactTooltip
                        place={"top"}
                        className={"tooltip-custom"}
                      />
                    </span>,
                    <span class={"icons"}>
                      {m.uploaded && (
                        <span
                          className="pointer"
                          onClick={() =>
                            this.openVideo(
                              m.filename,
                              m.provider,
                              m.account,
                              this.props.accounts
                            )
                          }
                        >
                          <Icon label={"media"} />
                        </span>
                      )}
                      {m.uploaded && (
                        <span
                          className={"pointer"}
                          onClick={() =>
                            this.openFile(
                              m.filename,
                              m.provider,
                              m.account,
                              this.props.accounts
                            )
                          }
                        >
                          <Icon label={"download"} />
                        </span>
                      )}
                    </span>,
                  ]}
                />
              ))}
          </TableBody>
        </Table>
        <div className={"view-media"}>
          {this.state.openVideo && (
            <Modal maxWidth={"600"}>
              <ModalHeader
                onClose={() => this.closeVideo()}
                title={"View Media"}
              />
              <ModalBody>
                <video src={this.state.video} controls></video>
              </ModalBody>
            </Modal>
          )}
        </div>
      </div>
    );
  }
}

export default ListMedia;
