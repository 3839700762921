import React from "react";
import {
  Table,
  TableHeader,
  TableRow,
  TableBody,
  Badge,
  Ellipse,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "@kerberos-io/ui";
import CRUDAccount from "./CRUDAccount";
import "./ListAccounts.scss";

export class ListAccounts extends React.Component {
  constructor() {
    super();
    this.state = {
      openConfirm: false,
      currentAccount: null,
      confirmCallback: null,
      account: "",
    };

    this.removeAccount = this.removeAccount.bind(this);
    this.editAccount = this.editAccount.bind(this);
    this.closeConfirm = this.closeConfirm.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
  }

  removeAccount(a) {
    this.setState({
      openConfirm: true,
      currentAccount: a,
      confirmCallback: () => {
        this.props.removeAccount(a);
        this.closeConfirm();
      },
    });
  }

  changeHandler(event, field) {
    this.setState({
      [field]: event.target.value,
    });
    console.log(this.state)
  }

  editAccount(a) {
    this.props.editAccount(a);
  }

  closeConfirm() {
    this.setState({
      openConfirm: false,
      confirmCallback: null,
    });
  }

  canBeRemoved() {
    return this.state.account === this.state.currentAccount.account;
  }

  render() {
    const accounts = this.props.accounts;
    return (
      <div className={"list-accounts"}>
        <Table>
          <TableHeader>
            <TableRow headercells={["active", "account", "provider", ""]} />
          </TableHeader>
          <TableBody>
            {accounts &&
              accounts.map((a) => (
                <TableRow
                  key={a.account}
                  bodycells={[
                    <Badge
                      title={a.enabled === "true" ? "enabled" : "disabled"}
                      status={a.enabled === "true" ? "success" : "secondary"}
                    />,
                    <span className={"bundle-fields"}>
                      <Ellipse status={"hub"} />
                      <b>{a.account}</b>
                      <p>({a.directory})</p>
                    </span>,
                    <span className={"bundle-fields"}>
                      <p>{a.provider}</p>
                    </span>,
                    <>
                      <CRUDAccount
                        mode="Update"
                        account={a}
                        onSubmit={this.editAccount}
                      >
                        <Button type="outlined" icon="pencil" label="" />
                      </CRUDAccount>
                      <span className={"delete"}>
                        <Button
                          onClick={() => this.removeAccount(a)}
                          type="outlined"
                          icon="trush"
                          label=""
                        />
                      </span>
                    </>,
                  ]}
                />
              ))}
          </TableBody>
        </Table>

        {this.state.openConfirm && (
          <Modal maxWidth={"600"}>
            <ModalHeader
              onClose={() => this.closeConfirm()}
              title={"Are you sure you want to delete?"}
            />
            <ModalBody>
              By removing the account <b>{this.state.currentAccount.account}</b>
              , the account will no longer work, and the connection settings
              will be lost.
              <Input
                placeholder={`Type the name "${this.state.currentAccount.account}" of the account, you would like to remove.`}
                type={"text"}
                onChange={(event) => this.changeHandler(event, "account")}
              />
            </ModalBody>
            <ModalFooter
              right={
                <>
                  <Button
                    disabled={!this.canBeRemoved()}
                    onClick={this.canBeRemoved() && this.state.confirmCallback}
                    label={"Delete"}
                    icon={"trush"}
                    type={this.canBeRemoved() ? "submit" : "neutral"}
                    buttontype={"submit"}
                  />
                </>
              }
            />
          </Modal>
        )}
      </div>
    );
  }
}

export default ListAccounts;
