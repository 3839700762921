import React from "react";
import { connect } from "react-redux";
import "./CRUDStorageProvider.scss";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  TextArea,
  Icon,
  Dropdown,
  Button,
  Toggle,
  InfoBar,
} from "@kerberos-io/ui";

class CRUDStorageProvider extends React.Component {
  constructor() {
    super();
    this.state = {
      open: false,
      id: "",
      name: "",
      provider: "gcp",
      enabled: "false",
      temporary: "false",
      host: "",
      region: "",
      bucket: "",
      access_key: "",
      secret: "",
      serviceKey: "",
      use_ssl: "",
      errors: {},
      error: false,
      validated: false,
    };
  }

  componentDidMount() {
    this.submitProvider = this.submitProvider.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.validateProvider = this.validateProvider.bind(this);
    this.verifyProvider = this.verifyProvider.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.changeProvider = this.changeProvider.bind(this);
    this.escFunction = this.escFunction.bind(this);

    const sp = this.props.storageprovider;
    if (sp) {
      this.setState({ ...sp });
    }

    document.addEventListener("keydown", this.escFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      //Do whatever when esc is pressed
      this.handleClose();
    }
  }

  changeProvider(value) {
    this.setState({
      provider: value[0],
    });
  }

  handleChange(event) {
    this.setState({
      provider: event.target.value,
    });
  }

  validateProvider() {
    if (this.state && this.state.provider) {
      const verification = this.verifyProvider();

      if (!verification.error) {
        this.props.onValidate(verification.payload);
      } else {
        // ....
      }

      return { payload: verification.payload, error: verification.error };
    }

    return { payload: {}, error: true };
  }

  verifyProvider() {
    let error = false;

    const payload = {
      id: this.state.id,
      provider: this.state.provider,
      enabled: this.state.enabled,
    };

    const { name } = this.state;
    const errors = {};
    payload.name = name;
    if (name === "") {
      errors["name"] = "Please specify a name for the provider";
      error = true;
    }

    if (payload.provider === "gcp") {
      const { bucket, serviceKey } = this.state;

      payload.bucket = bucket;
      if (bucket === "") {
        errors["bucket"] =
          "Provide the bucket where the data will be send stored";
        error = true;
      }

      payload.serviceKey = serviceKey;
      if (serviceKey === "") {
        errors["serviceKey"] = "Please specify a service key";
        error = true;
      }
    } else if (payload.provider === "aws") {
      const { region, bucket, access_key, secret } = this.state;

      payload.region = region;
      if (region === "") {
        errors["region"] = "Provide the region where S3 is running";
        error = true;
      }

      payload.bucket = bucket;
      if (bucket === "") {
        errors["bucket"] =
          "Provide the bucket where the data will be send stored";
        error = true;
      }

      payload.access_key = access_key;
      if (access_key === "") {
        errors["access_key"] = "The access key of your IAM user";
        error = true;
      }

      payload.secret = secret;
      if (secret === "") {
        errors["secret"] = "The secret access key of your IAM user";
        error = true;
      }
    } else if (payload.provider === "minio") {
      const { use_ssl, region, host, bucket, access_key, secret } = this.state;

      payload.use_ssl = use_ssl === "true" ? "true" : "false";

      payload.region = region;
      if (region === "") {
        errors["region"] = "Provide the region where Minio is running";
        error = true;
      }
      payload.host = host;
      if (host === "") {
        errors["host"] = "Provide the hostname of Minio";
        error = true;
      }

      payload.bucket = bucket;
      if (bucket === "") {
        errors["bucket"] =
          "Provide the bucket where the data will be send stored";
        error = true;
      }

      payload.access_key = access_key;
      if (access_key === "") {
        errors["access_key"] = "The access key of your IAM user";
        error = true;
      }

      payload.secret = secret;
      if (secret === "") {
        errors["secret"] = "The secret access key of your IAM user";
        error = true;
      }
    } else if (payload.provider === "storj") {
      const { region, host, bucket, access_key, secret } = this.state;

      payload.region = region;
      if (region === "") {
        errors["region"] = "Provide the region where Minio is running";
        error = true;
      }
      payload.host = host;
      if (host === "") {
        errors["host"] = "Provide the hostname of Minio";
        error = true;
      }

      payload.bucket = bucket;
      if (bucket === "") {
        errors["bucket"] =
          "Provide the bucket where the data will be send stored";
        error = true;
      }

      payload.access_key = access_key;
      if (access_key === "") {
        errors["access_key"] = "The access key of your IAM user";
        error = true;
      }

      payload.secret = secret;
      if (secret === "") {
        errors["secret"] = "The secret access key of your IAM user";
        error = true;
      }
    }

    this.setState({
      error: error,
      errors: errors,
      validated: true,
    });

    return { payload, error, errors };
  }

  submitProvider(event) {
    const valid = this.verifyProvider();
    if (!valid.error && this.props.onSubmit) {
      this.props.onSubmit(valid.payload);
      this.handleClose();
    }
    event.preventDefault();
    return false;
  }

  changeHandler(event, field) {
    this.setState({
      [field]: event.target.value,
    });
  }

  handleClickOpen() {
    this.setState({
      open: true,
    });
  }

  handleClose() {
    this.setState({
      open: false,
    });
  }

  render() {
    const providers = [
      { label: "Google Cloud Platform: storage", value: "gcp" },
      { label: "Amazon Web Services: S3", value: "aws" },
      { label: "Minio", value: "minio" },
      { label: "Storj", value: "storj" },
    ];

    const { open, provider } = this.state;

    let providerName = "";
    if (provider) {
      const findProvider = providers.find((i) => i.value === provider);
      if (findProvider) {
        providerName = findProvider.label;
      }
    }

    return (
      <div className={"create-provider"}>
        {open && (
          <Modal>
            <form onSubmit={(e) => this.submitProvider(e)} noValidate>
              <ModalHeader
                title={
                  providerName !== ""
                    ? `${this.props.mode} a ${providerName} provider`
                    : `${this.props.mode} an provider`
                }
                onClose={() => this.handleClose()}
              />
              <ModalBody>
                {/* Once an provider is selected, provide the connection details */}
                {this.state.validated && !this.state.error && (
                  <div>
                    {this.props.providerStatus === "pending" && (
                      <InfoBar
                        message={"Verifying your configuration"}
                        type={"loading"}
                      />
                    )}
                    {this.props.providerStatus === "error" && (
                      <InfoBar
                        message={
                          "Configuration is not correct and not working: " +
                          this.props.providerError
                        }
                        type={"alert"}
                      />
                    )}
                    {this.props.providerStatus === "success" && (
                      <InfoBar
                        message={"Configuration is valid and working"}
                        type={"success"}
                      />
                    )}{" "}
                  </div>
                )}

                <div className={"grid-container --two-columns"}>
                  <div className={"block"}>
                    <div className={"title"}>
                      <Icon label={"cloud"} />
                      <h1>Select Storage Provider</h1>
                    </div>
                    <Dropdown
                      icon={"counting"}
                      placeholder={"Select your provider"}
                      onChange={this.changeProvider}
                      isRadio={true}
                      selected={[this.state.provider]}
                      items={providers}
                    ></Dropdown>

                    <div className={"title"}>
                      <Icon label={"info"} />
                      <h1>Connection</h1>
                      <div
                        className={"toggle-integration"}
                        onClick={() =>
                          this.setState({
                            enabled:
                              this.state.enabled === "true" ? "false" : "true",
                          })
                        }
                      >
                        <Toggle on={this.state.enabled === "true"} />
                        <span>
                          {this.state.enabled === "true"
                            ? "Enabled"
                            : "Disabled"}
                        </span>
                      </div>
                    </div>

                    <Input
                      label={"Provider name"}
                      placeholder={"Provide a good name"}
                      type={"text"}
                      value={this.state.name}
                      error={this.state.errors["name"]}
                      onChange={(event) => this.changeHandler(event, "name")}
                    />

                    <Input
                      label={"Bucket name"}
                      placeholder={"Provide the bucket name"}
                      type={"text"}
                      value={this.state.bucket}
                      error={this.state.errors["bucket"]}
                      onChange={(event) => this.changeHandler(event, "bucket")}
                    />

                    {/* GCP Storage credentials */}
                    {provider === "gcp" && <></>}

                    {/* AWS S3 credentials */}
                    {provider === "aws" && (
                      <>
                        <Input
                          label={"Region"}
                          placeholder={"Provide the region of your S3 bucket"}
                          type={"text"}
                          value={this.state.region}
                          error={this.state.errors["region"]}
                          onChange={(event) =>
                            this.changeHandler(event, "region")
                          }
                        />
                      </>
                    )}

                    {/* Minio credentials */}
                    {provider === "minio" && (
                      <>
                        <Input
                          label={"Region"}
                          placeholder={
                            "Provide the region of your Minio bucket"
                          }
                          type={"text"}
                          value={this.state.region}
                          error={this.state.errors["region"]}
                          onChange={(event) =>
                            this.changeHandler(event, "region")
                          }
                        />
                        <Input
                          label={"Hostname"}
                          placeholder={"Hostname of Minio installation"}
                          type={"text"}
                          value={this.state.host}
                          error={this.state.errors["host"]}
                          onChange={(event) =>
                            this.changeHandler(event, "host")
                          }
                        />
                      </>
                    )}

                    {/* Storj credentials */}
                    {provider === "storj" && (
                      <>
                        <Input
                          label={"Region"}
                          placeholder={
                            "Provide the region of your Storj bucket"
                          }
                          type={"text"}
                          value={this.state.region}
                          error={this.state.errors["region"]}
                          onChange={(event) =>
                            this.changeHandler(event, "region")
                          }
                        />
                        <Input
                          label={"Hostname"}
                          placeholder={"Hostname of Storj installation"}
                          type={"text"}
                          value={this.state.host}
                          error={this.state.errors["host"]}
                          onChange={(event) =>
                            this.changeHandler(event, "host")
                          }
                        />
                      </>
                    )}
                  </div>

                  <div className={"block"}>
                    {/* GCP Storage credentials */}
                    {provider === "gcp" && (
                      <>
                        <div className={"title"}>
                          <Icon label={"lock"} />
                          <h1>GCP service key</h1>
                        </div>
                        <p>
                          To upload your recordings to Google Cloud storage,
                          make sure{" "}
                          <a
                            href={
                              "https://cloud.google.com/iam/docs/creating-managing-service-account-keys"
                            }
                            target={"_blank"}
                            rel={"noreferrer"}
                          >
                            you have created a Service Key
                          </a>{" "}
                          through the GCP console, and given it the correct
                          permissions.
                        </p>
                        <TextArea
                          label={"Service key"}
                          placeholder={
                            "Retrieve the access key from your IAM user"
                          }
                          type={"text"}
                          value={this.state.serviceKey}
                          error={this.state.errors["serviceKey"]}
                          onChange={(event) =>
                            this.changeHandler(event, "serviceKey")
                          }
                        />
                      </>
                    )}

                    {/* AWS S3 credentials */}
                    {provider === "aws" && (
                      <>
                        <div className={"title"}>
                          <Icon label={"lock"} />
                          <h1>AWS S3 credentials</h1>
                        </div>
                        <p>
                          To connect your AWS SQS queue, make sure{" "}
                          <a
                            href={
                              "https://docs.aws.amazon.com/AWSSimpleQueueService/latest/SQSDeveloperGuide/sqs-setting-up.html#sqs-getting-access-key-id-secret-access-key"
                            }
                            target={"_blank"}
                            rel={"noreferrer"}
                          >
                            you have created an IAM user
                          </a>{" "}
                          through the AWS console, and given it the correct
                          permissions.
                        </p>
                        <Input
                          label={"Access key"}
                          placeholder={
                            "Retrieve the access key from your IAM user"
                          }
                          type={"text"}
                          value={this.state.access_key}
                          error={this.state.errors["access_key"]}
                          onChange={(event) =>
                            this.changeHandler(event, "access_key")
                          }
                        />
                        <Input
                          label={"Secret access key"}
                          placeholder={
                            "Retrieve the secret access key from your IAM user"
                          }
                          type={"password"}
                          readonly={false}
                          disabled={false}
                          name="password"
                          iconleft=""
                          iconright="activity"
                          seperate
                          value={this.state.secret}
                          error={this.state.errors["secret"]}
                          onChange={(event) =>
                            this.changeHandler(event, "secret")
                          }
                        />
                      </>
                    )}

                    {/* Minio credentials */}
                    {provider === "minio" && (
                      <>
                        <div className={"title"}>
                          <Icon label={"lock"} />
                          <h1>Minio credentials</h1>
                        </div>
                        <p>
                          To connect your Minio installation, make sure{" "}
                          <a
                            href={
                              "https://docs.min.io/docs/minio-docker-quickstart-guide.html"
                            }
                            target={"_blank"}
                            rel={"noreferrer"}
                          >
                            you have created an access key and secret key.
                          </a>{" "}
                          Depending on how you installed it Docker/Kubernetes
                          you will be able to specify those credentials in a
                          config file.
                        </p>
                        <Input
                          label={"Access key"}
                          placeholder={"Retrieve the access key"}
                          type={"text"}
                          value={this.state.access_key}
                          error={this.state.errors["access_key"]}
                          onChange={(event) =>
                            this.changeHandler(event, "access_key")
                          }
                        />
                        <Input
                          label={"Secret access key"}
                          placeholder={"Retrieve the secret access key"}
                          type={"password"}
                          readonly={false}
                          disabled={false}
                          name="password"
                          iconleft=""
                          iconright="activity"
                          seperate
                          value={this.state.secret}
                          error={this.state.errors["secret"]}
                          onChange={(event) =>
                            this.changeHandler(event, "secret")
                          }
                        />

                        <div className={"title"}>
                          <Icon label={"lock"} />
                          <h1>SSL Enabled</h1>
                          <div
                            className={"toggle-integration"}
                            onClick={() =>
                              this.setState({
                                use_ssl:
                                  this.state.use_ssl === "true"
                                    ? "false"
                                    : "true",
                              })
                            }
                          >
                            <Toggle on={this.state.use_ssl === "true"} />
                            <span>
                              {this.state.use_ssl === "true"
                                ? "Enabled"
                                : "Disabled"}
                            </span>
                          </div>
                        </div>
                        <p>
                          Enable SSL if you have installed SSL in your Minio
                          client (https). Disable if you are using http.
                        </p>
                      </>
                    )}

                    {/* Storj credentials */}
                    {provider === "storj" && (
                      <>
                        <div className={"title"}>
                          <Icon label={"lock"} />
                          <h1>Storj credentials</h1>
                        </div>
                        <p>
                          To connect your Storj installation, make sure to
                          enable the S3 Gateway API. Copy and paste your access
                          and secrey keys.
                        </p>
                        <Input
                          label={"Access key"}
                          placeholder={"Retrieve the access key"}
                          type={"text"}
                          value={this.state.access_key}
                          error={this.state.errors["access_key"]}
                          onChange={(event) =>
                            this.changeHandler(event, "access_key")
                          }
                        />
                        <Input
                          label={"Secret access key"}
                          placeholder={"Retrieve the secret access key"}
                          type={"password"}
                          readonly={false}
                          disabled={false}
                          name="password"
                          iconleft=""
                          iconright="activity"
                          seperate
                          value={this.state.secret}
                          error={this.state.errors["secret"]}
                          onChange={(event) =>
                            this.changeHandler(event, "secret")
                          }
                        />
                      </>
                    )}
                  </div>
                </div>
              </ModalBody>
              <ModalFooter
                right={
                  <>
                    <Button
                      label={"Validate"}
                      icon={"verify"}
                      type={"success"}
                      onClick={() => this.validateProvider()}
                    />
                    <Button
                      label={`${this.props.mode} provider`}
                      icon={
                        this.props.mode === "Add" ? "plus-circle" : "pencil"
                      }
                      disabled={
                        //!this.state.validated ||
                        //this.props.providerStatus !== "success" ||
                        //this.state.error
                        false
                      }
                      type={
                        //this.props.providerStatus === "success" &&
                        //this.state.validated &&
                        //!this.state.error ? "submit" : "disabled"
                        "submit"
                      }
                      buttonType={"submit"}
                    />
                  </>
                }
              />
            </form>
          </Modal>
        )}
        <span onClick={() => this.handleClickOpen()}>
          {this.props.children}
        </span>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  providerStatus: state.storage.providerStatus,
  providerError: state.storage.providerError,
});

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CRUDStorageProvider);
