import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CRUDStorageProvider from "../../components/StorageProvider/CRUDStorageProvider";
import ListStorageProviders from "../../components/StorageProvider/ListStorageProviders";
import {
  getStorageProvider,
  addStorageProvider,
  editStorageProvider,
  removeStorageProvider,
  testStorageProvider,
} from "../../actions";
import "./StorageProviders.css";
import "typeface-roboto";
import {
  Breadcrumb,
  Button,
  ControlBar,
  InfoBox,
  Input,
  SetupBox,
} from "@kerberos-io/ui";

class StorageProviders extends React.Component {
  constructor() {
    super();
    this.state = {
      search: "",
    };

    this.getStorageProviders = this.getStorageProviders.bind(this);
    this.addStorageProvider = this.addStorageProvider.bind(this);
    this.editStorageProvider = this.editStorageProvider.bind(this);
    this.removeStorageProvider = this.removeStorageProvider.bind(this);
    this.filterProviders = this.filterProviders.bind(this);
    this.validateStorageProvider = this.validateStorageProvider.bind(this);
  }

  componentDidMount() {
    this.getStorageProviders();
  }

  getStorageProviders() {
    this.props.dispatchGetStorageProvider();
  }

  addStorageProvider(data) {
    this.props.dispatchAddStorageProvider(data, this.getStorageProviders);
  }

  validateStorageProvider(data) {
    this.props.dispatchTestStorageProvider(data, () => {});
  }

  editStorageProvider(data) {
    this.props.dispatchEditStorageProvider(data, this.getStorageProviders);
  }

  removeStorageProvider(data) {
    this.props.dispatchRemoveStorageProvider(data.id, this.getStorageProviders);
  }

  filterProviders(e) {
    const { value } = e.target;
    this.setState({
      search: value,
    });
  }

  render() {
    const { search } = this.state;
    const providers = this.props.providers
      ? this.props.providers.filter((p) => p.name.indexOf(search) >= 0)
      : [];
    return (
      <>
        <Breadcrumb
          title={"Storage Providers"}
          level1={"Connect the object storage your prefer."}
          level1Link={""}
        >
          <CRUDStorageProvider
            mode="Add"
            onSubmit={this.addStorageProvider}
            getStorageProviders={this.getStorageProviders}
            onValidate={this.validateStorageProvider}
          >
            <Button
              label={"Add Storage Provider"}
              type={"default"}
              icon={"plus-circle"}
            />
          </CRUDStorageProvider>
        </Breadcrumb>
        <ControlBar>
          <Input
            iconleft="search"
            placeholder="Search providers..."
            layout={"controlbar"}
            onChange={this.filterProviders}
            type="text"
          />
        </ControlBar>
        <div className={"info"}>
          <InfoBox
            image={"info-heavy-box"}
            title={"Connect your Storage Providers"}
            description={
              "At least one Storage Provider is needed to use Kerberos Vault – or you can connect more than one Provider for redundancy and resiliency. Connecting one Provider multiple times (e.g. in different regions, like EU & US) is also possible to improve performance. Missing your Provider?"
            }
          ></InfoBox>
        </div>

        {providers.length > 0 && (
          <ListStorageProviders
            getStorageProviders={this.getStorageProviders}
            editStorageProvider={this.editStorageProvider}
            removeStorageProvider={this.removeStorageProvider}
            validateStorageProvider={this.validateStorageProvider}
            providers={providers}
          />
        )}

        {providers.length === 0 && (
          <SetupBox
            dashed={true}
            header={"Add your storage provider"}
            text={
              "It looks like you didn't add an storage provider so far, or the storage provider you are looking for doesn't exists."
            }
            btnicon={"counting"}
            button={
              <CRUDStorageProvider
                mode="Add"
                onSubmit={this.addStorageProvider}
              >
                <Button
                  label={"Add storage provider"}
                  icon={"plus-circle"}
                  type={"default"}
                  onValidate={this.validateStorageProvider}
                />
              </CRUDStorageProvider>
            }
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  providers: state.storage.providers,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatchGetStorageProvider: () => dispatch(getStorageProvider()),
  dispatchTestStorageProvider: (data, success) =>
    dispatch(testStorageProvider(data, success)),
  dispatchAddStorageProvider: (data, success) =>
    dispatch(addStorageProvider(data, success)),
  dispatchEditStorageProvider: (data, success) =>
    dispatch(editStorageProvider(data, success)),
  dispatchRemoveStorageProvider: (id, success) =>
    dispatch(removeStorageProvider(id, success)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StorageProviders)
);
