import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import RefreshIcon from '@material-ui/icons/Refresh';
import './ListUsers.css';

export class ListUsers extends React.Component {

  constructor() {
    super();
    this.state = {
      container: null,
      hostname: "",
      port: "",
      open: false,
      openLogs: false,
      openConfirm: false,
      confirmCallback: null,
    };
  }

  componentDidMount() {
    this.removeDeployment = this.removeDeployment.bind(this);
    this.openContainer = this.openContainer.bind(this);
    this.openLogs = this.openLogs.bind(this);
    this.closeContainer = this.closeContainer.bind(this);
    this.closeLogs = this.closeLogs.bind(this);
    this.closeConfirm = this.closeConfirm.bind(this);
  }

  startContainer(id) {
    this.props.startContainer(id);
  }

  stopContainer(id) {
    this.props.stopContainer(id);
  }

  removeDeployment(id) {
    this.setState({
      openConfirm: true,
      confirmCallback: () => this.props.removeDeployment(id)
    });
  }

  openContainer(container, hostname, port) {
    this.setState({
      container,
      hostname,
      port,
      open: true,
    });
  }

  closeContainer() {
    this.setState({
      container: null,
      open: false,
    });
  }

  openLogs(container, port) {
    this.setState({
      container,
      port,
      openLogs: true,
    });
  }

  closeLogs() {
    this.setState({
      container: null,
      openLogs: false,
    });
  }

  closeConfirm() {
    this.setState({
      openConfirm: false,
      confirmCallback: null
    });
  }

  render() {
    const subs = this.props.subscriptions;
    return <div>
      <Paper className="root">
        <Table className="table">
          <TableHead>
            <TableRow>
              <TableCell>Plan Name</TableCell>
              <TableCell>Level</TableCell>
              <TableCell>Day Limit</TableCell>
              <TableCell>Usage (MB)</TableCell>
              <TableCell>Upload Limit (#)</TableCell>
              <TableCell>Video Limit (#)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { subs && subs.map && Object.keys(subs.map).map(s => <TableRow key={s}>
                <TableCell>{s}</TableCell>
                <TableCell>{subs.map[s].level}</TableCell>
                <TableCell>{subs.map[s].dayLimit}</TableCell>
                <TableCell>{subs.map[s].usage}</TableCell>
                <TableCell>{subs.map[s].uploadLimit}</TableCell>
                <TableCell>{subs.map[s].videoLimit}</TableCell>
              </TableRow>)
            }
          </TableBody>
        </Table>
      </Paper>

      <Tooltip title="Refresh deployments" placement="top">
        <Fab color="primary" aria-label="Refresh" className="refresh" onClick={this.props.getNodes}>
          <RefreshIcon/>
        </Fab>
      </Tooltip>
    </div>
  }
}

export default ListUsers;
