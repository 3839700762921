import React from "react";
import { connect } from "react-redux";
import { getStorageProvider, getQueue, getForwards } from "../../actions";
import "./CRUDCameras.scss";
import {
  Modal,
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@kerberos-io/ui";

class CRUDCameras extends React.Component {
  constructor() {
    super();
    this.state = {
      open: false,
      id: "",
      account: "",
      directory: "",
      provider: "",
      secondary_providers: [],
      queues: [],
      forward: "",
      edge_analysis: "false",
      access_key: "",
      secret_access_key: "",
      enabled: "true",
      limit: 30,
      errors: {},
      error: false,
      validated: false,
    };

    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.submitAccount = this.submitAccount.bind(this);
    this.verifyAccount = this.verifyAccount.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.handleChangeProvider = this.handleChangeProvider.bind(this);
    this.handleChangeSecondaryProviders =
      this.handleChangeSecondaryProviders.bind(this);
    this.handleChangeQueues = this.handleChangeQueues.bind(this);
    this.generateKeys = this.generateKeys.bind(this);
    this.makeKey = this.makeKey.bind(this);
    this.escFunction = this.escFunction.bind(this);
  }

  componentDidMount() {
    this.props.dispatchGetStorageProvider();
    this.props.dispatchGetForwards();
    this.props.dispatchGetQueue();
    const a = this.props.account;
    if (a) {
      this.setState({ ...a });
    }

    document.addEventListener("keydown", this.escFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      //Do whatever when esc is pressed
      this.handleClose();
    }
  }

  handleChangeProvider(provider) {
    if (provider.length > 0) {
      this.setState({
        provider: provider[0],
      });
    }
  }

  handleChangeSecondaryProviders(event) {
    const providers = event.target.value;
    const values = [];
    if (providers) {
      for (let i = 0, l = providers.length; i < l; i += 1) {
        if (providers[i]) {
          values.push(providers[i]);
        }
      }
    }
    this.setState({
      secondary_providers: values,
    });
  }

  handleChangeQueues(queues) {
    this.setState({
      queues: queues,
    });
  }

  changeHandler(event, field) {
    this.setState({
      [field]: event.target.value,
    });
  }

  handleClickOpen() {
    this.setState({
      open: true,
    });
  }

  handleClose() {
    this.setState({
      open: false,
    });
  }

  verifyAccount() {
    let error = false;

    const payload = {
      id: this.state.id,
      enabled: this.state.enabled,
    };

    const {
      account,
      provider,
      directory,
      limit,
      access_key,
      secret_access_key,
      queues,
    } = this.state;
    const errors = {};
    payload.account = account;
    if (account === "") {
      errors["account"] = "Please specify a account for the account";
      error = true;
    }

    payload.provider = provider;
    if (provider === "") {
      errors["provider"] = "Please select a provider";
      error = true;
    }

    payload.directory = directory;
    if (directory === "") {
      errors["directory"] = "Define a directory";
      error = true;
    }

    payload.limit = limit.toString();
    if (!parseInt(limit)) {
      errors["limit"] = "Please specify a day limit";
      error = true;
    }

    payload.access_key = access_key;
    if (access_key === "") {
      errors["access_key"] = "Specify a secure access key";
      error = true;
    }

    payload.secret_access_key = secret_access_key;
    if (secret_access_key === "") {
      errors["secret_access_key"] = "Specify a secret access key";
      error = true;
    }

    payload.queues = queues;

    this.setState({
      error: error,
      errors: errors,
      validated: true,
    });

    return { payload, error, errors };
  }

  submitAccount(event) {
    const valid = this.verifyAccount();
    if (!valid.error && this.props.onSubmit) {
      this.props.onSubmit(valid.payload);
      this.handleClose();
    }
    event.preventDefault();
    return false;
  }

  makeKey(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  generateKeys() {
    const access_key = this.makeKey(16);
    const secret_access_key = this.makeKey(26);
    this.setState({
      access_key,
      secret_access_key,
    });
  }

  render() {
    const { open, account } = this.state;
    return (
      <div className={"create-queue"}>
        {open && (
          <Modal>
            <form onSubmit={(e) => this.submitAccount(e)} noValidate>
              <ModalHeader
                title={
                  account !== ""
                    ? `${this.props.mode} the ${account} camera`
                    : `${this.props.mode} a camera`
                }
                onClose={() => this.handleClose()}
              />
              <ModalBody>
                <p>
                  To add a camera to your Kerberos Vault, go ahead and create an
                  account in your Kerberos Vault. Once you have a valid account,
                  you can setup your connection in your Kerberos agent.
                </p>
              </ModalBody>
              <ModalFooter
                right={
                  <Button
                    label={`Close`}
                    icon={"cancel"}
                    type={"neutral"}
                    onClick={() => this.handleClose()}
                    buttonType={"button"}
                  />
                }
              />
            </form>
          </Modal>
        )}
        <span onClick={() => this.handleClickOpen()}>
          {this.props.children}
        </span>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  providers: state.storage.providers,
  forwards: state.storage.forwards,
  queues: state.storage.queues,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatchGetStorageProvider: () => dispatch(getStorageProvider()),
  dispatchGetForwards: () => dispatch(getForwards()),
  dispatchGetQueue: () => dispatch(getQueue()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CRUDCameras);
