import React from "react";
import {
  Table,
  TableHeader,
  TableRow,
  TableBody,
  Badge,
  Ellipse,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "@kerberos-io/ui";
import "./ListCameras.scss";

export class ListCameras extends React.Component {
  constructor() {
    super();
    this.state = {
      openConfirm: false,
      currentCamera: null,
      confirmCallback: null,
      camera: "",
    };

    this.removeCamera = this.removeCamera.bind(this);
    this.closeConfirm = this.closeConfirm.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
  }

  removeCamera(c) {
    this.setState({
      openConfirm: true,
      currentCamera: c,
      confirmCallback: () => {
        this.props.removeCamera(c);
        this.closeConfirm();
      },
    });
  }

  changeHandler(event, field) {
    this.setState({
      [field]: event.target.value,
    });
  }

  closeConfirm() {
    this.setState({
      openConfirm: false,
      confirmCallback: null,
    });
  }

  canBeRemoved() {
    return (
      this.state.camera === this.state.currentCamera.analytics[0].cameraname
    );
  }

  render() {
    const cameras = this.props.cameras;
    return (
      <div className={"list-accounts"}>
        <Table>
          <TableHeader>
            <TableRow
              id={"header"}
              headercells={["active", "name", "version", ""]}
            />
          </TableHeader>
          <TableBody>
            {cameras &&
              cameras.map((a) => (
                <TableRow
                  id={a.key}
                  key={a.key}
                  bodycells={[
                    <Badge
                      title={
                        a.analytics[0].timestamp + 180 >
                        Math.floor(Date.now() / 1000)
                          ? "online"
                          : "offline"
                      }
                      status={
                        a.analytics[0].timestamp + 180 >
                        Math.floor(Date.now() / 1000)
                          ? "success"
                          : "secondary"
                      }
                    />,
                    <span className={"bundle-fields"}>
                      <Ellipse status={"hub"} />
                      <b>{a.analytics[0].cameraname}</b>
                      <p>({a.analytics[0].cameratype})</p>
                    </span>,
                    <span className={"bundle-fields"}>
                      <p>{a.analytics[0].version}</p>
                    </span>,
                    <>
                      {a.analytics[0].timestamp + 180 <=
                        Math.floor(Date.now() / 1000) && (
                        <span className={"delete"}>
                          <Button
                            onClick={() => this.removeCamera(a)}
                            type="outlined"
                            icon="trush"
                            label=""
                          />
                        </span>
                      )}
                    </>,
                  ]}
                />
              ))}
          </TableBody>
        </Table>

        {this.state.openConfirm && (
          <Modal maxWidth={"600"}>
            <ModalHeader
              onClose={() => this.closeConfirm()}
              title={"Are you sure you want to delete?"}
            />
            <ModalBody>
              By removing the account{" "}
              <b>{this.state.currentCamera.analytics[0].cameraname}</b>
              , the camera will be removed from the list.
              <Input
                placeholder={`Type the name "${this.state.currentCamera.analytics[0].cameraname}" of the camera, you would like to remove.`}
                type={"text"}
                onChange={(event) => this.changeHandler(event, "camera")}
              />
            </ModalBody>
            <ModalFooter
              right={
                <>
                  <Button
                    disabled={!this.canBeRemoved()}
                    onClick={(event) =>
                      this.canBeRemoved() && this.state.confirmCallback(event)
                    }
                    label={"Delete"}
                    icon={"trush"}
                    type={this.canBeRemoved() ? "submit" : "neutral"}
                    buttontype={"submit"}
                  />
                </>
              }
            />
          </Modal>
        )}
      </div>
    );
  }
}

export default ListCameras;
