import React from "react";
import ReactDOM from "react-dom";
import { Route, Switch } from "react-router-dom";
import { createStore, applyMiddleware } from "redux";
import { createBrowserHistory } from "history";
import { routerMiddleware, ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./reducers";
import thunk from "redux-thunk";
import App from "./App";
import Login from "./pages/Login/Login";
import Media from "./pages/Media/Media";
import Dashboard from "./pages/Dashboard/Dashboard";
import StorageProviders from "./pages/StorageProviders/StorageProviders";
import Accounts from "./pages/Accounts/Accounts";
import Queues from "./pages/Queues/Queues";
import Users from "./pages/Users/Users";
import Subscriptions from "./pages/Subscriptions/Subscriptions";
import RequireAuth from "./containers/RequireAuth";
import RequireGuest from "./containers/RequireGuest";
import * as serviceWorker from "./serviceWorker";
import "./index.scss";
import { Redirect } from "react-router";
import Cameras from "./pages/Cameras/Cameras";
export const history = createBrowserHistory();

// We get the token from the store to initialise the store.
// So we know if the user is still signed in.
function getAuthState() {
  try {
    const token = localStorage.getItem("token") || undefined;
    const expire = localStorage.getItem("expire") || undefined;
    const username = localStorage.getItem("username") || undefined;
    const role = localStorage.getItem("role") || undefined;
    const difference = new Date(expire) - new Date();
    const state = {
      auth: {
        token,
        expire,
        username,
        role,
        loggedIn: difference >= 0,
        loginError: false,
        error: "",
      },
    };
    return state;
  } catch (err) {
    return undefined;
  }
}

const store = createStore(
  rootReducer(history),
  { ...getAuthState() },
  composeWithDevTools(applyMiddleware(thunk, routerMiddleware(history)))
);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/login" component={RequireGuest(Login)} />
        <App>
          <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
          <Route exact path="/dashboard" component={RequireAuth(Dashboard)} />
          <Route path="/providers" component={RequireAuth(StorageProviders)} />
          <Route path="/queues" component={RequireAuth(Queues)} />
          <Route path="/accounts" component={RequireAuth(Accounts)} />
          <Route path="/cameras" component={RequireAuth(Cameras)} />
          <Route path="/media" component={RequireAuth(Media)} />
          <Route path="/users" component={RequireAuth(Users)} />
          <Route path="/subscriptions" component={RequireAuth(Subscriptions)} />
        </App>
      </Switch>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
