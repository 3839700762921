import API from './api';

export function doGetUsers(pagination, onSuccess, onError) {
  let endpoint = API.get(`cloud/users/${pagination.rows}/${pagination.page}`);
  endpoint.then(res => {
    if(res.status !== 200) {
      throw new Error(res.data)
    }
    return res.data;
  }).then(function (data) {
    onSuccess(data);
  }).catch(function (error) {
    onError(error);
  });
}

export function doGetSubscriptionSettings(onSuccess, onError) {
  let endpoint = API.get(`cloud/subscriptionsettings`);
  endpoint.then(res => {
    if(res.status !== 200) {
      throw new Error(res.data)
    }
    return res.data;
  }).then(function (data) {
    onSuccess(data);
  }).catch(function (error) {
    onError(error);
  });
}
