import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CRUDAccount from "../../components/Accounts/CRUDAccount";
import ListAccounts from "../../components/Accounts/ListAccounts";
import {
  getAccount,
  addAccount,
  editAccount,
  removeAccount,
} from "../../actions";
import "./Accounts.css";
import "typeface-roboto";
import {
  Breadcrumb,
  Button,
  ControlBar,
  InfoBox,
  Input,
  SetupBox,
} from "@kerberos-io/ui";

class Accounts extends React.Component {
  constructor() {
    super();
    this.state = {
      search: "",
    };
    this.getAccounts = this.getAccounts.bind(this);
    this.addAccount = this.addAccount.bind(this);
    this.editAccount = this.editAccount.bind(this);
    this.removeAccount = this.removeAccount.bind(this);
    this.filterAccounts = this.filterAccounts.bind(this);
  }

  componentDidMount() {
    this.getAccounts();
  }

  getAccounts() {
    this.props.dispatchGetAccounts();
  }

  addAccount(data) {
    this.props.dispatchAddAccount(data, this.getAccounts);
  }

  editAccount(data) {
    this.props.dispatchEditAccount(data, this.getAccounts);
  }

  removeAccount(data) {
    this.props.dispatchRemoveAccount(data.id, this.getAccounts);
  }

  filterAccounts(e) {
    const { value } = e.target;
    this.setState({
      search: value,
    });
  }

  render() {
    const { search } = this.state;
    const accounts = this.props.accounts
      ? this.props.accounts.filter((a) => a.account.indexOf(search) >= 0)
      : [];
    return (
      <>
        <Breadcrumb
          title={"Accounts"}
          level1={"Accounts allow you to secure your storage providers."}
          level1Link={""}
        >
          <CRUDAccount mode="Add" onSubmit={this.addAccount}>
            <Button
              label={"Add account"}
              type={"default"}
              icon={"plus-circle"}
            />
          </CRUDAccount>
        </Breadcrumb>
        <ControlBar>
          <Input
            iconleft="search"
            onChange={this.filterAccounts}
            placeholder="Search accounts..."
            layout={"controlbar"}
            type="text"
          />
        </ControlBar>
        <div className={"info"}>
          <InfoBox
            image={"info-add-user"}
            title={"Grant secure access"}
            description={
              "Connect your Kerberos agents to Kerberos Vault by providing secure credentials. By doing so you are hiding all the complexity of integrations and storage for your agents. Kerberos Vault acts as the single point of contact for any integration or extension you would like or already have build."
            }
          ></InfoBox>
        </div>
        {accounts.length > 0 && (
          <ListAccounts
            getAccounts={this.getAccounts}
            editAccount={this.editAccount}
            removeAccount={this.removeAccount}
            accounts={accounts}
          />
        )}

        {accounts.length === 0 && (
          <SetupBox
            dashed={true}
            header={"Add your account"}
            text={
              "It looks like you didn't add an account so far, or the account you are looking for doesn't exists."
            }
            btnicon={"counting"}
            button={
              <CRUDAccount mode="Add" onSubmit={this.addAccount}>
                <Button
                  label={"Add Account"}
                  icon={"plus-circle"}
                  type={"default"}
                />
              </CRUDAccount>
            }
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  accounts: state.storage.accounts,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatchGetAccounts: () => dispatch(getAccount()),
  dispatchAddAccount: (data, success) => dispatch(addAccount(data, success)),
  dispatchEditAccount: (data, success) => dispatch(editAccount(data, success)),
  dispatchRemoveAccount: (id, success) => dispatch(removeAccount(id, success)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Accounts)
);
