import React from "react";
import { connect } from "react-redux";
import { getStorageProvider, getQueue, getForwards } from "../../actions";
import "./CRUDAccount.scss";
import {
  Dropdown,
  Icon,
  Input,
  Modal,
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Toggle,
} from "@kerberos-io/ui";

class CRUDAccount extends React.Component {
  constructor() {
    super();
    this.state = {
      open: false,
      id: "",
      account: "",
      directory: "",
      provider: "",
      secondary_providers: [],
      queues: [],
      forward: "",
      edge_analysis: "false",
      cloud_analysis: "false",
      access_key: "",
      secret_access_key: "",
      enabled: "true",
      limit: 30,
      errors: {},
      error: false,
      validated: false,
    };

    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.submitAccount = this.submitAccount.bind(this);
    this.verifyAccount = this.verifyAccount.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.handleChangeProvider = this.handleChangeProvider.bind(this);
    this.handleChangeSecondaryProviders =
      this.handleChangeSecondaryProviders.bind(this);
    this.handleChangeQueues = this.handleChangeQueues.bind(this);
    this.generateKeys = this.generateKeys.bind(this);
    this.makeKey = this.makeKey.bind(this);
    this.escFunction = this.escFunction.bind(this);
  }

  componentDidMount() {
    this.props.dispatchGetStorageProvider();
    this.props.dispatchGetForwards();
    this.props.dispatchGetQueue();
    const a = this.props.account;
    if (a) {
      this.setState({ ...a });
    }

    document.addEventListener("keydown", this.escFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      //Do whatever when esc is pressed
      this.handleClose();
    }
  }

  handleChangeProvider(provider) {
    if (provider.length > 0) {
      this.setState({
        provider: provider[0],
      });
    }
  }

  handleChangeSecondaryProviders(event) {
    const providers = event.target.value;
    const values = [];
    if (providers) {
      for (let i = 0, l = providers.length; i < l; i += 1) {
        if (providers[i]) {
          values.push(providers[i]);
        }
      }
    }
    this.setState({
      secondary_providers: values,
    });
  }

  handleChangeQueues(queues) {
    this.setState({
      queues: queues,
    });
  }

  changeHandler(event, field) {
    this.setState({
      [field]: event.target.value,
    });
  }

  handleClickOpen() {
    this.setState({
      open: true,
    });
  }

  handleClose() {
    this.setState({
      open: false,
    });
  }

  verifyAccount() {
    let error = false;

    const payload = {
      id: this.state.id,
      enabled: this.state.enabled,
    };

    const {
      account,
      provider,
      directory,
      limit,
      access_key,
      secret_access_key,
      queues,
      edge_analysis,
      cloud_analysis,
    } = this.state;
    const errors = {};
    payload.account = account;
    if (account === "") {
      errors["account"] = "Please specify a account for the account";
      error = true;
    }

    payload.provider = provider;
    if (provider === "") {
      errors["provider"] = "Please select a provider";
      error = true;
    }

    payload.directory = directory;
    if (directory === "") {
      errors["directory"] = "Define a directory";
      error = true;
    }

    payload.limit = limit.toString();
    if (!parseInt(limit)) {
      errors["limit"] = "Please specify a day limit";
      error = true;
    }

    payload.access_key = access_key;
    if (access_key === "") {
      errors["access_key"] = "Specify a secure access key";
      error = true;
    }

    payload.secret_access_key = secret_access_key;
    if (secret_access_key === "") {
      errors["secret_access_key"] = "Specify a secret access key";
      error = true;
    }

    payload.queues = queues;
    payload.edge_analysis = edge_analysis;
    payload.cloud_analysis = cloud_analysis;

    this.setState({
      error: error,
      errors: errors,
      validated: true,
    });

    return { payload, error, errors };
  }

  submitAccount(event) {
    const valid = this.verifyAccount();
    if (!valid.error && this.props.onSubmit) {
      this.props.onSubmit(valid.payload);
      this.handleClose();
    }
    event.preventDefault();
    return false;
  }

  makeKey(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  generateKeys() {
    const access_key = this.makeKey(16);
    const secret_access_key = this.makeKey(26);
    this.setState({
      access_key,
      secret_access_key,
    });
  }

  render() {
    const { open, account } = this.state;

    const providerWithLabels = this.props.providers
      ? this.props.providers.map((p) => {
          return { label: p.name, value: p.name };
        })
      : [];

    const queueWithLabels = this.props.queues
      ? this.props.queues.map((q) => {
          return { label: q.name, value: q.name };
        })
      : [];

    return (
      <div className={"create-queue"}>
        {open && (
          <Modal>
            <form onSubmit={(e) => this.submitAccount(e)} noValidate>
              <ModalHeader
                title={
                  account !== ""
                    ? `${this.props.mode} the ${account} account`
                    : `${this.props.mode} an account`
                }
                onClose={() => this.handleClose()}
              />
              <ModalBody>
                <div className={"grid-container --two-columns"}>
                  <div className={"block"}>
                    <div className={"title"}>
                      <Icon label={"user"} />
                      <h1>Details</h1>
                      <div
                        className={"toggle-integration"}
                        onClick={() =>
                          this.setState({
                            enabled:
                              this.state.enabled === "true" ? "false" : "true",
                          })
                        }
                      >
                        <Toggle on={this.state.enabled === "true"} />
                        <span>
                          {this.state.enabled === "true"
                            ? "Enabled"
                            : "Disabled"}
                        </span>
                      </div>
                    </div>

                    <Input
                      label={"Account name"}
                      placeholder={"Provide a good name"}
                      type={"text"}
                      value={this.state.account}
                      error={this.state.errors["account"]}
                      onChange={(event) => this.changeHandler(event, "account")}
                    />

                    <Dropdown
                      label={"Main Provider"}
                      icon={"cloud"}
                      placeholder={"Select your provider"}
                      onChange={this.handleChangeProvider}
                      isRadio={true}
                      selected={[this.state.provider]}
                      error={this.state.errors["provider"]}
                      items={providerWithLabels}
                    ></Dropdown>

                    <Input
                      label={"Day limit"}
                      placeholder={"Expiry time of recordings"}
                      type={"text"}
                      value={this.state.limit}
                      error={this.state.errors["limit"]}
                      onChange={(event) => this.changeHandler(event, "limit")}
                    />

                    <Dropdown
                      label={"Integrations"}
                      icon={"counting"}
                      placeholder={"Select your integration"}
                      onChange={this.handleChangeQueues}
                      isRadio={false}
                      selected={this.state.queues}
                      items={queueWithLabels}
                    ></Dropdown>

                    <div className={"title"}>
                      <Icon label={"media"} />
                      <h1>Directory</h1>
                    </div>
                    <p>
                      All recordings uploaded to this account wll be stored in a
                      subdirectory at your storage provider. By specifying the (
                      <b>*</b>) value, your Kerberos agents will use a custom
                      directory.
                    </p>
                    <Input
                      placeholder={"Provide a directory value"}
                      type={"text"}
                      value={this.state.directory}
                      error={this.state.errors["directory"]}
                      onChange={(event) =>
                        this.changeHandler(event, "directory")
                      }
                    />
                  </div>

                  <div className={"block"}>
                    <div className={"title"}>
                      <Icon label={"lock"} />
                      <h1>Credentials</h1>
                    </div>
                    <p>
                      Following credentials will be used by your Kerberos agents
                      and Kerberos Hub to send and retrieve recordings from your
                      storage providers.
                    </p>

                    <Input
                      label={"Access key"}
                      placeholder={"Provide an access key"}
                      type={"text"}
                      value={this.state.access_key}
                      error={this.state.errors["access_key"]}
                      onChange={(event) =>
                        this.changeHandler(event, "access_key")
                      }
                    />

                    <Input
                      label={"Secret key"}
                      placeholder={"Provide a secret"}
                      type={"password"}
                      iconleft=""
                      iconright="activity"
                      seperate
                      value={this.state.secret_access_key}
                      error={this.state.errors["secret_access_key"]}
                      onChange={(event) =>
                        this.changeHandler(event, "secret_access_key")
                      }
                    />

                    <div className={"generate-keys"}>
                      <Button
                        label={`Generate keys`}
                        icon={"refresh"}
                        type={"success"}
                        buttonType={"button"}
                        onClick={() => this.generateKeys()}
                      />
                    </div>

                    <div className={"title"}>
                      <Icon label={"cloud"} />
                      <h1>Cloud analysis</h1>
                      <div
                        className={"toggle-integration"}
                        onClick={() =>
                          this.setState({
                            cloud_analysis:
                              this.state.cloud_analysis === "true"
                                ? "false"
                                : "true",
                          })
                        }
                      >
                        <Toggle on={this.state.cloud_analysis === "true"} />
                        <span>
                          {this.state.cloud_analysis === "true"
                            ? "Enabled"
                            : "Disabled"}
                        </span>
                      </div>
                    </div>
                    <p>
                      By enabling, data such as thumbnails, machine learning or
                      dominant colors is computed inside the Kerberos Hub
                      pipeline. This means recordings will be shared from
                      Kerberos Vault to Kerberos Hub.
                    </p>

                    <div className={"title"}>
                      <Icon label={"share"} />
                      <h1>Edge analysis</h1>
                      <div
                        className={"toggle-integration"}
                        onClick={() =>
                          this.setState({
                            edge_analysis:
                              this.state.edge_analysis === "true"
                                ? "false"
                                : "true",
                          })
                        }
                      >
                        <Toggle on={this.state.edge_analysis === "true"} />
                        <span>
                          {this.state.edge_analysis === "true"
                            ? "Enabled"
                            : "Disabled"}
                        </span>
                      </div>
                    </div>
                    <p>
                      Edge analysis will reduce bandwidth between Kerberos Vault
                      and Kerberos Hub. By enabling, data such as thumbnails, or
                      dominant colors is computed inside Kerberos Vault and not
                      in the Kerberos Hub pipeline.
                    </p>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter
                right={
                  <Button
                    label={`${this.props.mode} account`}
                    icon={this.props.mode === "Add" ? "plus-circle" : "pencil"}
                    type={"submit"}
                    buttonType={"submit"}
                  />
                }
              />
            </form>
          </Modal>
        )}
        <span onClick={() => this.handleClickOpen()}>
          {this.props.children}
        </span>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  providers: state.storage.providers,
  forwards: state.storage.forwards,
  queues: state.storage.queues,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatchGetStorageProvider: () => dispatch(getStorageProvider()),
  dispatchGetForwards: () => dispatch(getForwards()),
  dispatchGetQueue: () => dispatch(getQueue()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CRUDAccount);
